import React from 'react'
import { ButtonGroup, Button, Dropdown, NavbarCollapse } from 'react-bootstrap'

const DeleteControls = ({ onDeleteAll, onDeleteSelected }) => {

    const handleDeleteSelected = () => {
        onDeleteSelected()
    }

    const handleDeleteAll = () => {
        onDeleteAll()
    }

    return (
        <Dropdown
            variant='secondary'
            as={ButtonGroup}
            aria-label='Delete controls'
        >
            <Dropdown.Toggle variant="secondary" size='lg' id="dropdown-basic" title='Delete'>
                <i className="bi bi-trash"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item
                    className="d-flex align-items-center"
                    as={Button}
                    style={{ fontSize: '20px', height: 50 }}
                    onClick={handleDeleteSelected}
                >
                    <i className="bi bi-x-circle-fill"></i>&nbsp;Delete Selected</Dropdown.Item>
                <Dropdown.Item
                    className="d-flex align-items-center"
                    as={Button}
                    style={{ fontSize: '20px', height: 50 }}
                    onClick={handleDeleteAll}
                >
                    <i className="bi bi-trash-fill"></i>&nbsp;Delete All</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>        
    )
}

export default DeleteControls
