// GOM Objects properties
class GomObjectType {
    /**
    * Constructor for GomObject class.
    * @param {Object} options - Configuration options for GomObject.
    * @param {string} options.id - The unique identifier for the GomObject.
    * @param {string} options.type - The type of GomObject.
    * @param {string} options.title - The title of the GomObject.
    * @param {string} options.icon - The icon associated with the GomObject.
    * @param {boolean} options.widthText - Flag indicating whether text width should be considered.
    * @param {string} options.positionText - The position of the text relative to the GomObject ('top', 'right', 'center', 'bottom', 'left').
    * @param {Array} options.fieldsText - Array of text fields associated with the GomObject.
    * @param {number} options.angle - The rotation angle of the GomObject.
    * @param {boolean} options.withBorderColor - Flag indicating whether to include border color.
    * @param {number} options.withBorderWidth - The width of the border of the GomObject.
    * @param {boolean} options.withFillColor - Flag indicating whether to include fill color.
    * @param {Object} options.schema - JSON Schema format of the data fields associated with the GomObject.
    * @param {string} options.pathData - Path data from SVG object
    * @param {string} options.svgData - SVG data from SVG object
    */
    constructor({
        id,
        type,
        title,
        icon,
        widthText = false,
        positionText = 'top', // top - right - center - bottom - left
        fieldsText = [],
        endTextWidthStr = '',
        angle = 0,
        withBorderColor = true,
        withBorderWidth = 1,
        withFillColor = true,
        schema = {},  //JSON Schema format of the data fields
        pathData = null,
        svgData = null,
        history = [],
        formData = {},
        canvasObject = {},
        timestamp = false,
        createdAt = Date.now(),
        text = '',
    }) {
        this.id = id
        this.type = type
        this.title = title
        this.icon = icon
        this.widthText = widthText

        const allowedPositions = ['top', 'right', 'center', 'bottom', 'left']
        if (!allowedPositions.includes(positionText)) {
            throw new Error(`Invalid value for positionText. Allowed values are: ${allowedPositions.join(', ')}`)
        }
        this.positionText = positionText

        this.fieldsText = fieldsText
        this.positionText = positionText
        this.endTextWidthStr = endTextWidthStr
        this.angle = angle
        this.withBorderColor = withBorderColor
        this.withBorderWidth = withBorderWidth
        this.withFillColor = withFillColor
        this.schema = schema
        this.pathData = pathData
        this.svgData = svgData
        this.history = history
        this.formData = formData
        this.canvasObject = canvasObject
        this.timestamp = timestamp
        this.createdAt = createdAt
        this.text = text
    }

    /**
    * Get method to retrieve the current state of the GomObject.
    * @returns {Object} - The current state of the GomObject.
    */
    get = () => ({
        id: this.id,
        type: this.type,
        title: this.title,
        icon: this.icon,
        widthText: this.widthText,
        positionText: this.positionText,
        fieldsText: this.fieldsText,
        endTextWidthStr: this.endTextWidthStr,
        angle: this.angle,
        withBorderColor: this.withBorderColor,
        withBorderWidth: this.withBorderWidth,
        withFillColor: this.withFillColor,
        schema: this.schema,
        pathData: this.pathData,
        svgData: this.svgData,
        history: this.history,
        formData: this.formData,
        canvasObject: this.canvasObject,
        timestamp: this.timestamp,
        createdAt: this.createdAt,
        text: this.text
    })

    /**
    * Set object data.
    * @param {Object} formData - Optional parameters for adding a history entry.
    */
    setFormData = (formData) => {
        this.formData = formData
        if ( this.fieldsText.length > 0) {
            this.setFieldsText(this.formData)
        }
    }

    /**
     * Set text to show in canvas object. Get text from fieldsText array
     */
    setFieldsText = () => {
        this.text = ''
        this.fieldsText.map((field) => {
            this.text += this.formData[field] + ' '
        })
        this.text = this.text.trim() + (this.endTextWidthStr ? this.endTextWidthStr : '')
    }

    /**
    * Add a new entry to the history of the GomObject.
    * @param {Object} options - Optional parameters for adding a history entry.
    * @param {Object} options.canvasObject - The canvasObject associated with the GomObject. Defaults to null.
    */
    addHistory = ({ canvasObject = null }) => {
        //        if (this.data !== data || this.canvasObject !== canvasObject) {
        //            this.data = data
        this.canvasObject = canvasObject
        this.timestamp = Date.now()
        this.history.push({ ...this.canvasObject, timestamp: this.timestamp })
        //       }
    }

    /**
    * Get the entire history of the GomObject.
    * @returns {Array} - An array containing the historical entries of the GomObject.
    */
    getHistory = () => (this.history)

    /**
     * Clear the entire history of the GomObject.
     */
    clearHistory = () => {
        this.history = []
    }
}

export default GomObjectType