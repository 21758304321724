import React from 'react'
import { Form, ButtonGroup, Button, Dropdown } from 'react-bootstrap'

const ExportControls = ({ onExportToPNG, onExportToJSON, onImportFromJSON }) => {

    const handleExportToPNG = () => {
        onExportToPNG()
    }

    const handleExportToJSON = () => {
        onExportToJSON()
    }

    const handleImportFromJSON = (e) => {
        onImportFromJSON(e)
    }

    return (
        <Dropdown
            variant='secondary'
            as={ButtonGroup}
            aria-label='Export controls'
        >
            <Dropdown.Toggle variant="secondary" size='lg' id="dropdown-basic" title='Export canvas'>
                <i className="bi bi-box-arrow-down"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item
                    className="d-flex align-items-center"
                    as='label'
                    htmlFor='importJsonFile'
                    style={{ fontSize: '20px', height: 50 }}
                    title={'Import canvas from JSON file'}
                >
                    <i className="bi bi-filetype-json"></i>&nbsp;Import from JSON
                    <Form.Control
                        id="importJsonFile"
                        type="file"
                        onChange={handleImportFromJSON}
                        style={{ display: 'none' }}
                    />
                </Dropdown.Item>
                <Dropdown.Item
                    className="d-flex align-items-center"
                    as={Button}
                    style={{ fontSize: '20px', height: 50 }}
                    title={'Export canvas to image in PNG format'}
                    onClick={handleExportToPNG}
                >
                    <i className="bi bi-filetype-png"></i>&nbsp;Export to PNG</Dropdown.Item>
                <Dropdown.Item
                    className="d-flex align-items-center"
                    as={Button}
                    style={{ fontSize: '20px', height: 50 }}
                    title={'Export canvas to ascii file in JSON format'}
                    onClick={handleExportToJSON}
                >
                    <i className="bi bi-filetype-json"></i>&nbsp;Export to JSON</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ExportControls
