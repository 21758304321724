import { fabric } from 'fabric'
// http://fabricjs.com/docs/
import canvasConfig from "../../config/canvas.config"

class GomStarShape {
   
    constructor({ canvas, options }) {
        this.canvas = canvas
        this.options = options

        this.gomObjectType = options.gomObjectType
        this.widthText = options.gomObjectType.widthText
        this.withBorderWidth = options.gomObjectType.withBorderWidth
        this.angle = options.gomObjectType.angle

        this.color = options.color
        this.fill = options.fill
        this.borderCode = options.borderCode
        this.draw()
    }

    draw = () => {
        // Configuración del starburst
        const centerX = this.canvas.width / 2;
        const centerY = this.canvas.height / 2;
        const outerRadius = 50; // Radio externo de la estrella
        const innerRadius = 20; // Radio interno de la estrella
        const numberOfPoints = 5; // Número de puntas de la estrella
        const rotation = (Math.PI * 2) / numberOfPoints; // Ángulo de rotación entre cada punto

        // Calcular las coordenadas para la estrella
        const starCoords = []
        let firstVertexCoords = null

        for (let i = 0; i < numberOfPoints; i++) {
            const angleOuter = rotation * i
            const angleInner = rotation * (i + 0.5) // Ajuste para alternar entre puntas externas e internas
            const xOuter = centerX + outerRadius * Math.cos(angleOuter)
            const yOuter = centerY + outerRadius * Math.sin(angleOuter)
            const xInner = centerX + innerRadius * Math.cos(angleInner)
            const yInner = centerY + innerRadius * Math.sin(angleInner)

            // Almacenar las coordenadas del primer vértice orientado en vertical arriba
            if (i === 0) {
                firstVertexCoords = { x: xOuter, y: yOuter }
            }

            // Agregar las coordenadas del punto externo e interno
            starCoords.push({ x: xOuter, y: yOuter }, { x: xInner, y: yInner })
        }

        // Crear el objeto de la estrella usando fabric.Polygon
        const starIcon = new fabric.Polygon(starCoords, {
            left: this.canvas.width / 2,
            top: this.canvas.height / 2,
            stroke: this.color,
            strokeWidth: this.borderCode.strokeWidth,
            strokeDashArray: this.borderCode.strokeDashArray,
            fill: this.fill,
            angle: this.angle,
            originX: 'center',
            originY: 'center',
            cornerColor: canvasConfig.defaultCornerColor,
        })

        starIcon.scaleToWidth(canvasConfig.iconScaleWidth)

        if (!this.widthText) {
            this.canvas.add(starIcon)
        } else {
            const group = this.addToGroupWithText([starIcon], this.fill)
            this.canvas.add(group)
        }

        this.canvas.requestRenderAll()
    }

    addToGroupWithText = (events, textColor) => {
        const text = new fabric.Textbox('', {
            fontSize: canvasConfig.fonstSize,
            fill: textColor,
            originX: 'left',  // left, center, right
            originY: 'center',  // top, center, bottom
            left: events[0].left + 4,
            top: events[0].top,
            editable: true,
            angle: 0,
        })

        const group = new fabric.Group([...events, text], {
            gomObjectType: this.gomObjectType,
            top: text.top,
            left: text.left,
            originX: 'center',
            originY: 'center',
            cornerColor: canvasConfig.defaultCornerColor,
        })

        return group
    }
}

export default GomStarShape