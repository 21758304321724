export const getContrastColor = (backgroundColor) => {
    const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16)
        const r = (bigint >> 16) & 255
        const g = (bigint >> 8) & 255
        const b = bigint & 255
        return [r, g, b]
    }

    const [r, g, b] = hexToRgb(backgroundColor)
    const yiq = (r * 299 + g * 587 + b * 114) / 1000

    return yiq >= 128 ? 'black' : 'white'
}