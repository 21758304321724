import { fabric } from 'fabric'
// http://fabricjs.com/docs/
import canvasConfig from "../../config/canvas.config"

class GomPolygonShape {
   
    constructor({ canvas, options }) {
        this.canvas = canvas
        this.options = options
        this.editMode = 'scale'

        this.gomObjectType = options.gomObjectType
        this.widthText = options.gomObjectType.widthText
        this.withBorderWidth = options.gomObjectType.withBorderWidth
        this.angle = options.gomObjectType.angle
        
        this.color = options.color
        this.fill = options.fill
        this.borderCode = options.borderCode
        this.draw()
    }

    draw = () => {
        const canvas = this.canvas

        const points1 = [
            { x: 3, y: 4 },
            { x: 16, y: 3 },
            { x: 30, y: 5 },
            { x: 25, y: 55 },
            { x: 19, y: 44 },
            { x: 15, y: 30 },
            { x: 15, y: 55 },
            { x: 9, y: 55 },
            { x: 6, y: 53 },
            { x: -2, y: 55 },
            { x: -4, y: 40 },
            { x: 0, y: 20 }
        ];

        const numberOfSides = 12; // Number of sides for a hexagon
        const radius = 20; // Radius of the hexagon
        const centerX = 50; // X-coordinate of the center
        const centerY = 50; // Y-coordinate of the center

        const points = [];

        for (let i = 0; i < numberOfSides; i++) {
            const angle = (i * 2 * Math.PI) / numberOfSides;
            const x = centerX + radius * Math.cos(angle);
            const y = centerY + radius * Math.sin(angle);

            points.push({ x, y });
        }

        const polygonIcon = new fabric.Polygon(points, {
            gomObjectType: this.gomObjectType,
            left: 100,
            top: 50,
            fill: 'transparent',
            strokeWidth: this.withBorderWidth,
            stroke: this.color,
            strokeUniform: true, // Añadir esta línea para mantener el grosor uniforme
            strokeDashArray: this.borderCode.strokeDashArray, // Dash array -----
            scaleX: 1,
            scaleY: 1,
            objectCaching: false,
            transparentCorners: false,
            cornerColor: canvasConfig.defaultCornerColor,
            onEdit: this.handleEditClick,
            setEditMode: this.setEditMode,
            getEditMode: this.getEditMode,
            editMode: 'scale',
        });

        //canvas.viewportTransform = [0.7, 0, 0, 0.7, -50, 50];
        polygonIcon.scaleToWidth(canvasConfig.iconScaleWidth)

        canvas.add(polygonIcon);

        //polygon.on('selected', this.handleObjectSelected);
    };

    polygonPositionHandler = (dim, finalMatrix, fabricObject, pointIndex) => {
        var x = (fabricObject.points[pointIndex].x - fabricObject.pathOffset.x),
            y = (fabricObject.points[pointIndex].y - fabricObject.pathOffset.y);
        return fabric.util.transformPoint(
            { x: x, y: y },
            fabric.util.multiplyTransformMatrices(
                fabricObject.canvas.viewportTransform,
                fabricObject.calcTransformMatrix()
            )
        );
    }

    actionHandler = (eventData, transform, x, y) => {
        var polygon = transform.target,
            currentControl = polygon.controls[polygon.__corner],
            mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
            polygonBaseSize = getObjectSizeWithStroke(polygon),
            size = polygon._getTransformedDimensions(0, 0),
            finalPointPosition = {
                x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
                y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
            };
        polygon.points[currentControl.pointIndex] = finalPointPosition;
        return true;
    }

    // define a function that can keep the polygon in the same position when we change its
    // width/height/top/left.
    anchorWrapper = (anchorIndex, fn) => {
        return function (eventData, transform, x, y) {
            var fabricObject = transform.target,
                absolutePoint = fabric.util.transformPoint({
                    x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
                    y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
                }, fabricObject.calcTransformMatrix()),
                actionPerformed = fn(eventData, transform, x, y),
                newDim = fabricObject._setPositionDimensions({}),
                polygonBaseSize = getObjectSizeWithStroke(fabricObject),
                newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
                newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
            fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
            return actionPerformed;
        }
    }

    handleObjectSelected = ({ target }) => {
        //target.edit = !target.edit;

        if (this.editMode === 'vertices') {
            const lastControl = target.points.length - 1;
            target.cornerStyle = 'circle';
            target.cornerColor = 'rgba(0,0,255,0.5)';
            target.controls = target.points.reduce((acc, point, index) => {
                acc['p' + index] = new fabric.Control({
                    positionHandler: (dim, finalMatrix, fabricObject) => this.polygonPositionHandler(dim, finalMatrix, fabricObject, index),
                    actionHandler: this.anchorWrapper(index > 0 ? index - 1 : lastControl, this.actionHandler),
                    actionName: 'modifyPolygon',
                    pointIndex: index
                });

                return acc;
            }, {});
        } else {
            target.cornerColor = 'blue';
            target.cornerStyle = 'rect';
            target.controls = fabric.Object.prototype.controls;
        }

        //target.hasBorders = !this.editMode;
        this.canvas.requestRenderAll();
    };

    handleEditClick = () => {
        const poly = this.canvas.getActiveObject();
        this.handleObjectSelected({ target: poly });
    };

    setEditMode = (mode) => {
        this.editMode = mode
        return this.editMode
    }

    getEditMode = () => {
        return this.editMode
    }

}

const getObjectSizeWithStroke = (object) => {
    var stroke = new fabric.Point(
        object.strokeUniform ? 1 / object.scaleX : 1,
        object.strokeUniform ? 1 / object.scaleY : 1
    ).multiply(object.strokeWidth);
    return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
}

export default GomPolygonShape