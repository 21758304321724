// useGomDB.js
import { useState, useEffect } from 'react'
import GomDBManager from '../lib/GomDBManager'

const useGomDB = () => {
    const [lastRecord, setLastRecord] = useState(null)
    const [updateDate, setUpdateDate] = useState(null)
    const gomDBManager = new GomDBManager('gomfireDb', 'canvas', 1)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const db = await gomDBManager.openDatabase()
                const lastRecord = await gomDBManager.getLastRecord()
                setLastRecord(lastRecord)
                setUpdateDate(lastRecord ? lastRecord.id : Date.now())
            } catch (error) {
                console.error(error)
            }
        }

        fetchData()

        return () => {
            // Limpiar recursos si es necesario
        }
    }, [])

    const addRecord = async (data) => {
        try {
            const db = await gomDBManager.openDatabase()
            await gomDBManager.addRecord(JSON.stringify(data))
            setUpdateDate(Date.now())
        } catch (error) {
            console.error(error)
        }
    }

    const clearAllRecords = async () => {
        try {
            const db = await gomDBManager.openDatabase()
            await gomDBManager.clearAllRecords()
            console.log('Todos los registros han sido eliminados')
        } catch (error) {
            console.error(error)
        }
    }

    const deleteDatabase = async () => {
        try {
            await gomDBManager.deleteDatabase()
            console.log('Base de datos eliminada correctamente')
        } catch (error) {
            console.error(error)
        }
    }

    return {
        lastRecord,
        updateDate,
        addRecord,
        clearAllRecords,
        deleteDatabase
    }
}

export default useGomDB
