import React, { useRef, useEffect, useState } from 'react'
import { fabric } from 'fabric'
// http://fabricjs.com/docs/fabric.Object.html

import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import GomCanvas from './lib/GomCanvas'
import GomObjectType from './lib/GomObjectType'

import { getContrastColor } from './helper/color.helper'

import ColorPicker from './components/ColorPicker'
import ObjectPicker from './components/ObjectPicker'
import ZoomControls from './components/ZoomControls'
import ImportExportControls from './components/ImportExportControls'
import DeleteControls from './components/DeleteControls'
import InfoModal from './components/InfoModal'
import CopyModal from './components/CopyModal'
import moment from 'moment'
import { borderCodes, ActiveBorderSVG, ExpectedBorderSVG, SectorBorderSVG, NoBorderSVG } from './config/border.config'


const Canvas = ({
    width = window.innerWidth,      // Canvas width in pixels
    height = window.innerHeight,    // Canvas height in pixels
    selection = false,              // Canvas multiple objets selection
    onAdd = false,                  // Event on object added
    onSelection = null,             // Event on object selection
    onChange = null,                // Event on object change
    onDeleteAll = null,             // Event on delete all canvas objects
    backGroundImage = null,         // Background image
    onBackgroundChange = null,      // On background change
    canvasJSON = null               // Canvas JSON data from indexedDB
}) => {

    const isTablet = /iPad|Android|Tablet/i.test(navigator.userAgent)

    const canvasRef = useRef(null)
    const [canvas, setCanvas] = useState(null)
    const [colorCode, setColorCode] = useState({ title: 'Default', name: 'black', color: '#000000', fill: '#000000', icon: 'bi bi-sign-merge-right' })
    const [borderCode, setBorderCode] = useState('active')
    const [changeColorDisabled, setChangeColorDisabled] = useState(true)
    const [zoom, setZoom] = useState(1)
    const [drawActive, setDrawActive] = useState(false)
    const [draggableCanvas, setDraggableCanvas] = useState(false)
    const [elementSelected, setElementSelected] = useState(null)
    const [elementEditMode, setElementEditMode] = useState('scale')
    const [elementLockMovement, setElementLockMovement] = useState(false)

    useEffect(() => {
        // Crear una instancia la interface GomCanvas que utiliza Fabric.js canvas
        const newCanvas = new GomCanvas(canvasRef)

        setCanvas(newCanvas)

        return () => {
            // Limpiar cualquier recurso al desmontar el componente
            newCanvas.getDispose()
        }
    }, [])

    useEffect(() => {
        if (!canvasJSON) return
        const jsonData = JSON.parse(canvasJSON.value)
        if (jsonData) {
            canvas.deleteAll()
            canvas.setFromJSON(jsonData)
        }
    }, [canvasJSON])

    useEffect(() => {
        if (!canvas) return
        canvas.setOptions({
            backgroundColor: '#ffffff',
            selection: selection,
            //perPixelTargetFind: true,
            //targetFindTolerance: 70,
            background: 'white',
            //enableRetinaScaling: true,
            allowTouchScrolling: true,
            grid: 10,
        })

        canvas.setPencilBrush({ color: 'black', width: 2, strokeWidth: borderCodes[borderCode].strokeWidth, strokeDashArray: borderCodes[borderCode].strokeDashArray, strokeUniform: true })

        canvas.addOn('selection:created', handleOnSelection)
        canvas.addOn('selection:updated', handleOnSelection)
        canvas.addOn('selection:cleared', handleOnSelectionCleared)
        canvas.addOn('object:added', handleOnAdd)
        canvas.addOn('object:modified', handleOnChange)
        canvas.addOn('backgroundImage:added', handleOnBackgroundChange)

        canvas.addOn('mouse:dblclick', (event) => {
            const clickedObject = event.target
            setInfoModalShow((s) => !s)
        })

        setZoom(canvas.getZoom())

    }, [canvas])

    // Cuando se selecciona un objeto
    const handleOnSelection = (event) => {
        if (event.selected[0].editMode) {
            setElementEditMode(event.selected[0].getEditMode())
        } else {
            setElementEditMode(false)
        }
        setElementSelected(event.selected[0])
        setElementLockMovement(event.selected[0].lockMovementX && event.selected[0].lockMovementY)
        onSelection && onSelection(event)
        setChangeColorDisabled(false)
    }

    // Cuando se deselecciona un objeto
    const handleOnSelectionCleared = (event) => {
        setElementEditMode('scale')
        setElementSelected(null)
        setElementLockMovement(false)
        onSelection && onSelection(event)
        setChangeColorDisabled(true)
    }

    // Cuando se da de alta un objeto
    const handleOnAdd = (event) => {
        if (event.target.gomObjectType !== 'background-grid' && event.target.type !== 'path') {
            if (event.target.gomObjectType instanceof GomObjectType) {
                event.target.gomObjectType.addHistory({ canvasObject: event.target.toObject() })
            } else {
                event.target.gomObjectType = new GomObjectType(event.target.gomObjectType)
                event.target.gomObjectType.addHistory({ canvasObject: event.target.toObject() })
            }
        }
        onAdd && onAdd(canvas.toJSON(), event)
    }

    // Cuando se cambia un objeto
    const handleOnChange = (event) => {
        event.target.gomObjectType.addHistory({ canvasObject: event.target.toObject() })
        onChange && onChange(canvas.toJSON(), event.target)
    }

    // Cuando se cambia el background del canvas
    const handleOnBackgroundChange = () => {
        onBackgroundChange && onBackgroundChange()
    }

    const handleSetColorToSelected = () => {
        canvas.setColorToSelected({ color: colorCode.color })
    }

    const handleSetFillToSelected = () => {
        canvas.setFillToSelected({ fill: colorCode.fill })
    }

    const handleSetBorderToSelected = () => {
        canvas.setBorderToSelected({ borderCode: borderCodes[borderCode], colorCode: colorCode })
    }

    const handleDeleteAll = () => {
        canvas.deleteAll()
        onDeleteAll && onDeleteAll()
    }

    const handleDeleteSelected = () => {
        canvas.deleteSelected()
    }

    const handleColorAndBorderChange = (colorCode, borderCode) => {
        setColorCode(colorCode)
        setBorderCode(borderCode)
    }

    // Handle Background File Change
    const handleFileChange = async (event) => {
        const file = event.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onload = () => {
                const base64DataUrl = reader.result
                canvas.setBackgroundImage(base64DataUrl)
            }
            reader.readAsDataURL(file)
        }
    }

    // Handle import canvas from JSON file
    const handleImportCanvasFromJSON = async (event) => {
        const file = event.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onload = () => {
                const jsonData = JSON.parse(reader.result)
                console.log("descarga el jsonData", jsonData)
                if (jsonData) {
                    canvas.setFromJSON(jsonData)
                }
            }
            reader.readAsText(file)
        }
    }

    // Handle export canvas to PNG image
    const handleExportCanvasToPNG = () => {
        canvas.getCanvasImage({ format: 'png', quality: 1 })
    }

    // Handle export canvas to JSON file
    const handleExportCanvasToJSON = () => {
        canvas.getCanvasJSON()
    }

    // Handle Add Object Picker 
    const handleAddGomObject = (object) => {
        canvas.addGomObject({
            gomObjectType: object,
            color: colorCode.color,
            fill: colorCode.fill,
            borderCode: borderCodes[borderCode]
        })
    }

    const handleZoom = (zoom) => {
        canvas.setZoom(zoom)
    }

    const handleGrid = (grid) => {
        if (grid) {
            canvas.setGrid(10)
        } else {
            canvas.setGrid(0)
        }
    }

    const handleDrawPencil = () => {
        canvas.setPencilBrush({ color: colorCode.fill, width: 2, strokeWidth: borderCodes[borderCode].strokeWidth, strokeDashArray: borderCodes[borderCode].strokeDashArray, strokeUniform: true, gomObjectType: { type: 'drawPencil', timestamp: Date.now() } })
        canvas.setDrawMode({ active: drawActive ? false : true })
        setDrawActive(drawActive ? false : true)
    }

    const handleDraggableCanvas = () => {
        canvas.setDraggable(draggableCanvas ? false : true)
        setDraggableCanvas(draggableCanvas ? false : true)
    }

    const [infoModalShow, setInfoModalShow] = useState(false)
    const handleInfoModalClose = () => setInfoModalShow(false)
    const toggleInfoModalShow = () => setInfoModalShow((s) => !s)
    const handleInfoModalUpdate = (formData) => {
        elementSelected.gomObjectType.setFormData(formData)
        canvas.setText(elementSelected.gomObjectType.text)
        setInfoModalShow(false)
    }

    const [copyModalShow, setCopyModalShow] = useState(false)
    const handleCopyModalClose = () => setCopyModalShow(false)
    const toggleCopyModalShow = () => setCopyModalShow((s) => !s)


    const toggleElementEditMode = () => {
        if (elementSelected.editMode) {
            elementSelected.setEditMode(elementEditMode === 'scale' ? 'vertices' : 'scale')
            elementSelected.onEdit()
            setElementEditMode(elementEditMode === 'scale' ? 'vertices' : 'scale')
        }
    }

    const toggleElementBringToFront = () => {
        canvas.bringToFront()
    }

    const toggleElementSendToBack = () => {
        canvas.sendToBack()
    }

    const toggleLockElementMovement = () => {
        setElementLockMovement(!elementLockMovement)
        canvas.lockMovement(!elementLockMovement)
    }

    return (
        <Container fluid>
            <Row className='p-1'>
                <Col className='col-12 d-flex'>
                    <ButtonToolbar aria-label="GOM Toolbar">
                        <ButtonGroup size='lg' className="me-2" aria-label="Draw control">
                            <Button variant={drawActive ? 'primary' : 'secondary'} size='lg' onClick={handleDrawPencil} title='Pencil' active={drawActive ? 'active' : ''}><i className="bi bi-pencil"></i></Button>
                        </ButtonGroup>

                        <ObjectPicker onAddObject={handleAddGomObject} />
                        &nbsp;

                        <ButtonGroup size='lg' className="me-2" aria-label="Element control">
                            <Button
                                variant={elementSelected ? 'primary' : 'secondary'}
                                size='lg'
                                onClick={toggleInfoModalShow}
                                title='Element Properties'
                                active={elementSelected ? 'active' : ''}
                                disabled={!elementSelected ? 'disabled' : ''}
                            >
                                <i className="bi bi-tag-fill"></i>
                            </Button>

                            <Button
                                variant={elementSelected && elementEditMode ? 'primary' : 'secondary'}
                                size='lg'
                                onClick={toggleElementEditMode}
                                title='Element Editor'
                                active={elementSelected && elementEditMode ? 'active' : ''}
                                disabled={!elementSelected || !elementEditMode ? 'disabled' : ''}
                            >
                                {elementEditMode !== 'vertices' ?
                                    <i className="bi bi-bounding-box"></i>
                                    :
                                    <i className="bi bi-bounding-box-circles"></i>
                                }
                            </Button>

                            <Button
                                variant={elementSelected ? 'primary' : 'secondary'}
                                size='lg'
                                onClick={toggleElementBringToFront}
                                title='Bring to Front'
                                active={elementSelected ? 'active' : ''}
                                disabled={!elementSelected ? 'disabled' : ''}
                            >
                                <i className="bi bi-front"></i>
                            </Button>

                            <Button
                                variant={elementSelected ? 'primary' : 'secondary'}
                                size='lg'
                                onClick={toggleElementSendToBack}
                                title='Send to Back'
                                active={elementSelected ? 'active' : ''}
                                disabled={!elementSelected ? 'disabled' : ''}
                            >
                                <i className="bi bi-back"></i>
                            </Button>

                            <Button
                                variant={elementSelected ? 'primary' : 'secondary'}
                                size='lg'
                                onClick={toggleLockElementMovement}
                                title='Lock Element'
                                active={elementSelected ? 'active' : ''}
                                disabled={!elementSelected ? 'disabled' : ''}
                            >
                                {elementLockMovement ?
                                    <i className="bi bi-lock"></i>
                                    :
                                    <i className="bi bi-unlock"></i>
                                }
                            </Button>
                        </ButtonGroup>

                        <ButtonGroup size='lg' className="me-2" aria-label="Colors & Border Control">
                            <Button
                                size='lg'
                                onClick={handleSetBorderToSelected}
                                disabled={changeColorDisabled ? 'disabled' : ''}
                                title='Set border code to selected'
                                style={{
                                    backgroundColor: colorCode.color,
                                    borderColor: colorCode.color,
                                    color: getContrastColor(colorCode.color)
                                }}>
                                {borderCode === 'expected' ?
                                    <ExpectedBorderSVG strokeColor={getContrastColor(colorCode.color)} />
                                    : borderCode === 'sector' ?
                                        <SectorBorderSVG strokeColor={getContrastColor(colorCode.color)} />
                                        : borderCode === 'active' ?
                                            <ActiveBorderSVG strokeColor={getContrastColor(colorCode.color)} />
                                            :
                                            <NoBorderSVG strokeColor={getContrastColor(colorCode.color)} />
                                }
                            </Button>
                            <Button
                                size='lg'
                                onClick={handleSetColorToSelected}
                                disabled={changeColorDisabled ? 'disabled' : ''}
                                title='Set text color to selected'
                                style={{
                                    backgroundColor: colorCode.color,
                                    borderColor: colorCode.color,
                                    color: getContrastColor(colorCode.color)
                                }}>
                                <i className="bi bi-brush"></i>
                            </Button>
                            <Button
                                size='lg'
                                onClick={handleSetFillToSelected}
                                disabled={changeColorDisabled ? 'disabled' : ''}
                                title='Set fill color to selected'
                                style={{
                                    backgroundColor: colorCode.fill,
                                    borderColor: colorCode.fill,
                                    color: getContrastColor(colorCode.fill)
                                }}>
                                <i className="bi bi-paint-bucket"></i>
                            </Button>
                        </ButtonGroup>
                        <ColorPicker
                            onChange={handleColorAndBorderChange}
                            colorCode={colorCode}
                            borderCode={borderCode}
                        />
                        &nbsp;

                        <ButtonGroup aria-label="Background and Images Controls">
                            <Button variant='secondary' size='lg' onClick={() => canvas.setBackgroundImage(backGroundImage)} title='Set default background image'><i className="bi bi-image"></i></Button>
                            <Button variant='secondary' as="label" htmlFor="imageFileBg" size='lg' title='Upload background image'><i className="bi bi-file-earmark-image"></i></Button>
                            <Form.Control
                                id="imageFileBg"
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <Button variant={draggableCanvas ? 'primary' : 'secondary'} size='lg' onClick={handleDraggableCanvas} title='Move canvas' active={draggableCanvas ? 'active' : ''}><i className="bi bi-arrows-move"></i></Button>
                        </ButtonGroup>
                        &nbsp;

                        <ZoomControls
                            onZoomChange={handleZoom}
                            onGrid={handleGrid}
                        />

                        <DeleteControls
                            onDeleteAll={handleDeleteAll}
                            onDeleteSelected={handleDeleteSelected}
                        />
                        &nbsp;

                        <ImportExportControls
                            onImportFromJSON={handleImportCanvasFromJSON}
                            onExportToPNG={handleExportCanvasToPNG}
                            onExportToJSON={handleExportCanvasToJSON}
                        />
                        &nbsp;

                        <Button
                            variant="warning"
                            className="d-inline-flex align-items-center"
                            onClick={toggleCopyModalShow}
                        >
                            <Image src='/logo_gom_fire_trans.png' width={80} />
                        </Button>
                    </ButtonToolbar>
                </Col>
            </Row>

            <canvas
                ref={canvasRef}
                width={width}
                height={height}
                style={{ border: '1px solid #ccc' }}
            >
                Your Web browser don't support canvas!
            </canvas>

            {infoModalShow && elementSelected &&
                <InfoModal
                    show={infoModalShow}
                    onClose={handleInfoModalClose}
                    onUpdate={handleInfoModalUpdate}
                    element={elementSelected}
                />
            }

            {copyModalShow &&
                <CopyModal
                    show={copyModalShow}
                    onClose={handleCopyModalClose}
                />
            }

        </Container>
    )
}

export default Canvas
