import GomObjectType from "../../lib/GomObjectType"

const resourceBrigadeRect = new GomObjectType({
    id: 'resourceBrigade',
    type: 'path',
    title: 'Brigade',
    icon: 'bi bi-asterisk',
    widthText: true,
    positionText: 'right', // top - right - center - bottom - left
    fieldsText: ["name"],
    endTextWidthStr: "",
    angle: 0,
    withBorderColor: true,
    withBorderWidth: 0,
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "name": {
                "title": "Resource name",
                "description": "Resource name",
                "type": "string",
            },
        },
        "required": ['name']
    },
    pathData: 'M4 50 L174 50 M85 0 L85 50 M4 50 L4 150 L174 150 L174 50',
    svgData: `<svg width="180" height="155" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="50" width="170" height="100" stroke="black" fill="none" stroke-width="4" />
    <line x1="85" y1="0" x2="85" y2="50" stroke="black" stroke-width="4" />
  </svg>`,
})

export default resourceBrigadeRect