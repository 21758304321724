import GomObjectType from "../../lib/GomObjectType"

const actionWaterWheel = new GomObjectType({
    id: 'actionWaterWheel',
    type: 'path',
    title: 'Water Wheel Action',
    icon: 'bi bi-asterisk',
    widthText: true,
    positionText: 'center', // top - right - center - bottom - left
    fieldsText: ["name"],
    endTextWidthStr: "",
    angle: 0,
    withBorderColor: true,
    withBorderWidth: 0,
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "name": {
                "title": "Action name",
                "description": "Action name",
                "type": "string",
            },
        },
        "required": ['name']
    },
    pathData: 'M1860 2436 l0 -136 -383 0 c-464 0 -560 -10 -719 -74 -180 -73 -356 -221 -456 -385 -346 -565 -29 -1298 621 -1436 38 -8 83 -15 101 -15 l33 0 7 102 c3 57 9 140 13 186 l6 82 -35 0 c-45 0 -130 26 -199 60 -132 67 -242 197 -290 341 -34 105 -34 253 0 358 48 144 158 274 290 341 125 63 127 63 588 68 l422 4 3 -140 3 -140 392 227 c216 124 393 228 393 231 0 3 -163 100 -362 215 -200 115 -378 218 -395 228 l-33 19 0 -136 M2756 2256 c-3 -23 -8 -107 -12 -188 l-7 -148 37 0 c21 0 71 -11 113 -25 369 -124 518 -557 302 -880 -77 -114 -186 -194 -329 -241 -48 -15 -108 -18 -477 -22 l-422 -4 -3 140 -3 139 -170 -98 c-93 -54 -270 -156 -392 -226 -123 -70 -223 -131 -223 -134 0 -3 62 -41 137 -84 76 -44 253 -146 393 -227 l255 -148 3 135 3 135 382 0 c464 0 560 10 719 74 180 73 356 221 456 385 153 250 183 554 82 834 -47 128 -119 238 -229 347 -155 153 -330 239 -548 268 l-61 8 -6 -40z',
    svgData: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="381.000000pt" height="270.000000pt" viewBox="0 0 381.000000 270.000000"
    preserveAspectRatio="xMidYMid meet">
   
       <g transform="translate(0.000000,270.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
           <path d="M1860 2436 l0 -136 -383 0 c-464 0 -560 -10 -719 -74 -180 -73 -356
           -221 -456 -385 -346 -565 -29 -1298 621 -1436 38 -8 83 -15 101 -15 l33 0 7
           102 c3 57 9 140 13 186 l6 82 -35 0 c-45 0 -130 26 -199 60 -132 67 -242 197
           -290 341 -34 105 -34 253 0 358 48 144 158 274 290 341 125 63 127 63 588 68
           l422 4 3 -140 3 -140 392 227 c216 124 393 228 393 231 0 3 -163 100 -362 215
           -200 115 -378 218 -395 228 l-33 19 0 -136 M2756 2256 c-3 -23 -8 -107 -12 -188 l-7 -148 37 0 c21 0 71 -11 113
           -25 369 -124 518 -557 302 -880 -77 -114 -186 -194 -329 -241 -48 -15 -108
           -18 -477 -22 l-422 -4 -3 140 -3 139 -170 -98 c-93 -54 -270 -156 -392 -226
           -123 -70 -223 -131 -223 -134 0 -3 62 -41 137 -84 76 -44 253 -146 393 -227
           l255 -148 3 135 3 135 382 0 c464 0 560 10 719 74 180 73 356 221 456 385 153
           250 183 554 82 834 -47 128 -119 238 -229 347 -155 153 -330 239 -548 268
           l-61 8 -6 -40z"/>
       </g>
   </svg>
   `,
})

export default actionWaterWheel