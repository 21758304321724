import React, { useState } from 'react'
import { ButtonGroup, ToggleButton, Button, Popover, Row, OverlayTrigger, Form, Badge } from 'react-bootstrap'

import { getContrastColor } from '../helper/color.helper'
import { ActiveBorderSVG, ExpectedBorderSVG, SectorBorderSVG, NoBorderSVG } from '../config/border.config'

const ColorPicker = ({ colorCode = null, borderCode = null, onChange }) => {
    const colorsCode = [
        { title: 'People', name: 'green', color: '#008000', fill: '#008000', icon: 'bi bi-people-fill' },
        { title: 'Fire', name: 'red', color: '#FF0000', fill: '#FF0000', icon: 'bi bi-fire' },
        { title: 'Water', name: 'blue', color: '#4169E1', fill: '#4169E1', icon: 'bi bi-water' },
        { title: 'Risk', name: 'orange', color: '#FFC300', fill: '#FFC300', icon: 'bi bi-exclamation-triangle-fill' },
        { title: 'Command', name: 'rebeccapurple', color: '#663399', fill: '#663399', icon: 'bi bi-person-badge' },
        { title: 'Default', name: 'black', color: '#000000', fill: '#000000', icon: 'bi bi-sign-merge-right' },
    ]

    const [selectedColorCode, setSelectedColorCode] = useState(colorCode ? colorCode : colorsCode[5]) // Código de colores
    const [selectedBorderCode, setSelectedBorderCode] = useState(borderCode ? borderCode : 'active')

    const handleOnChange = (color, border) => {
        onChange(color, border)
    }

    const handleColorChange = ({ color = null, fill = null }) => {
        const foundColorCode = colorsCode.find(colorCode => colorCode.fill === fill)
        let newColorCode = foundColorCode
        if (!newColorCode) {
            newColorCode = {
                ...selectedColorCode,
                title: 'Others',
                name: 'black',
                color: color ? color : selectedColorCode.color,
                fill: fill ? fill : selectedColorCode.fill,
                icon: 'bi bi-palette-fill'
            }
        }
        setSelectedColorCode(newColorCode)
        handleOnChange(newColorCode, selectedBorderCode)
    }

    const handleBorderChange = (newBorderCode) => {
        setSelectedBorderCode(newBorderCode)
        handleOnChange(selectedColorCode, newBorderCode)
    }

    const renderColorPalette = () => {
        return (
            <ButtonGroup size='lg' className="me-2" aria-label="Colors Code">
                {
                    colorsCode.map((colorCode, index) => (
                        <Button
                            key={'colors-code-' + index}
                            size='lg'
                            style={{ width: 50, backgroundColor: colorCode.fill, color: getContrastColor(colorCode.fill), borderColor: colorCode.fill }}
                            onClick={() => handleColorChange({ color: colorCode.color, fill: colorCode.fill })}
                            title={colorCode.title}
                            active={selectedColorCode.color === colorCode.color ? 'active': ''}
                        >
                            <i className={colorCode.icon} />
                        </Button>
                    ))
                }
            </ButtonGroup>
        )
    }

    const renderBorderPalette = () => {
        return (
            <ButtonGroup size='lg' className="me-2" aria-label="Borders code">
                <ToggleButton
                    type="radio"
                    variant="outline-info"
                    name="borderCode"
                    value={'active'}
                    checked={selectedBorderCode === 'active'}
                    onClick={() => handleBorderChange('active')}
                >
                    <ActiveBorderSVG />
                    &nbsp;Active
                </ToggleButton>
                <ToggleButton
                    type="radio"
                    variant="outline-info"
                    name="borderCode"
                    value={'expected'}
                    checked={selectedBorderCode === 'expected'}
                    onClick={() => handleBorderChange('expected')}
                >
                    <ExpectedBorderSVG />
                    &nbsp;Expected
                </ToggleButton>
                <ToggleButton
                    type="radio"
                    variant="outline-info"
                    name="borderCode"
                    value={'sector'}
                    checked={selectedBorderCode === 'sector'}
                    onClick={() => handleBorderChange('sector')}
                >
                    <SectorBorderSVG />
                    &nbsp;Sector
                </ToggleButton>
                <ToggleButton
                    type="radio"
                    variant="outline-info"
                    name="borderCode"
                    value={'noBorder'}
                    checked={selectedBorderCode === 'noBorder'}
                    onClick={() => handleBorderChange('noBorder')}
                >                    
                    <NoBorderSVG />
                    &nbsp;No border
                </ToggleButton>
            </ButtonGroup>
        )

    }


    const colorPickerPopover = (
        <Popover id="color-picker-popover" style={{ maxWidth: '600px', width: '100%', fontSize: '1.25rem' }}>
            <Popover.Header className="d-flex justify-content-between align-items-center">
                <div className="mb-0">
                    <i className="bi bi-palette-fill"></i> Selected color &nbsp;
                    {selectedColorCode ?
                        <Badge
                            bg=''
                            style={{
                                backgroundColor: selectedColorCode.fill,
                                color: getContrastColor(selectedColorCode.fill)
                            }}>
                            <i className={selectedColorCode.icon}></i> {selectedColorCode.title}
                        </Badge>
                        :
                        <Badge bg='' style={{ backgroundColor: selectedColorCode.fill, color: getContrastColor(selectedColorCode.fill) }}>Others</Badge>
                    }
                </div>
                <div className="ml-auto d-flex align-items-center">
                    <Form.Label className="mb-0"><i className="bi bi-brush"></i>&nbsp;</Form.Label>
                    <Form.Control
                        type="color"
                        value={selectedColorCode.color}
                        onChange={(e) => handleColorChange({ color: e.target.value })}
                    />
                    &nbsp;
                    <Form.Label className="mb-0"><i className="bi bi-paint-bucket"></i>&nbsp;</Form.Label>
                    <Form.Control
                        type="color"
                        value={selectedColorCode.fill}
                        onChange={(e) => handleColorChange({ fill: e.target.value })}
                    />
                </div>
            </Popover.Header>
            <Popover.Body>
                <Row>
                    {renderColorPalette()}
                </Row>
                <hr />
                <Row>
                    {renderBorderPalette()}
                </Row>
            </Popover.Body>
        </Popover>
    )

    return (
        <OverlayTrigger trigger="click" placement="bottom" overlay={colorPickerPopover}>
            <Button size='lg' style={{ backgroundColor: selectedColorCode.fill, borderColor: selectedColorCode.fill, color: getContrastColor(selectedColorCode.fill) }}>
                {selectedColorCode ?
                    <>
                        <i className={selectedColorCode.icon}></i> {selectedColorCode.title}
                    </>
                    :
                    <i className="bi bi-palette-fill"></i>
                }
            </Button>
        </OverlayTrigger>
    )
}

export default ColorPicker
