import GomObjectType from "../../lib/GomObjectType"

const incidentCatStarBurst = new GomObjectType({
    id: 'incidentStarBurst',
    type: 'path',
    title: 'Category 3',
    icon: 'bi bi-asterisk',
    widthText: true,
    positionText: 'right', // top - right - center - bottom - left
    fieldsText: ["name", "victims"],
    endTextWidthStr: "victims",
    angle: 0,
    withBorderColor: true,
    withBorderWidth: 2,
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "name": {
                "title": "Incident",
                "description": "Incident description",
                "type": "string",
            },
            "address": {
                "title": "Address",
                "description": "Incident address",
                "type": "string",
            },
            "victims": {
                "title": "Victims",
                "description": "Incident victims",
                "type": "integer",
            },
        },
        "required": ['name', "address"]
    },
    pathData: "M890 925 c-63 -63 -119 -115 -124 -115 -5 0 -39 26 -75 58 -36 32 -72 62 -78 66 -24 14 -52 -15 -79 -84 -15 -38 -33 -70 -39 -70 -7 0 -106 36 -221 79 -181 68 -212 77 -229 66 -43 -27 -31 -49 79 -140 58 -48 106 -90 106 -94 0 -4 -46 -13 -102 -20 -111 -14 -128 -21 -128 -57 0 -18 17 -32 96 -74 70 -38 93 -55 85 -63 -6 -6 -46 -29 -89 -52 -63 -32 -78 -45 -80 -66 -6 -48 12 -52 179 -40 83 7 153 10 155 8 2 -2 -3 -24 -12 -50 -33 -98 -6 -116 106 -68 40 17 76 31 80 31 4 0 15 -42 25 -92 23 -124 23 -126 46 -138 33 -18 54 5 109 125 30 63 57 114 60 113 4 -2 37 -38 75 -81 60 -69 71 -78 98 -75 30 3 31 5 55 98 13 52 25 96 26 98 2 1 53 -27 113 -63 112 -66 139 -75 161 -53 18 18 15 35 -19 121 -17 43 -29 80 -26 83 2 3 58 1 124 -3 139 -9 150 -8 158 18 12 37 -5 54 -97 97 -52 23 -88 46 -82 50 5 5 39 21 76 36 40 17 70 35 73 47 7 21 -10 59 -26 59 -24 0 -204 23 -208 26 -2 2 10 22 29 45 28 35 31 44 21 65 -13 28 -24 29 -166 10 -55 -7 -101 -12 -103 -11 -2 2 1 45 6 96 8 79 7 97 -6 112 -8 9 -20 17 -26 17 -6 0 -63 -52 -126 -115z m56 -120 c-5 -47 -3 -58 14 -75 13 -13 28 -18 47 -15 72 13 143 22 143 18 0 -3 -9 -16 -20 -30 -37 -47 -20 -64 77 -78 l78 -12 -50 -24 c-39 -19 -51 -30 -53 -51 -3 -22 3 -30 35 -47 l38 -20 -52 -1 c-33 0 -57 -6 -67 -15 -14 -14 -13 -21 5 -71 11 -30 19 -59 17 -64 -2 -5 -41 13 -88 40 -47 27 -88 50 -91 50 -23 1 -43 -35 -58 -104 l-17 -78 -63 71 c-104 117 -110 116 -173 -19 l-41 -85 -16 75 c-15 69 -18 75 -43 78 -15 2 -48 -6 -74 -17 -52 -24 -61 -22 -45 7 18 33 13 70 -9 82 -13 7 -48 9 -93 5 -68 -6 -70 -5 -46 9 58 34 44 65 -52 118 -32 18 -56 35 -54 39 3 3 37 10 76 13 100 10 119 19 119 55 0 21 -9 36 -36 56 -19 15 -34 29 -32 31 2 2 53 -15 112 -37 125 -48 130 -46 161 46 11 30 22 55 25 55 3 0 30 -25 60 -55 76 -77 90 -76 186 25 43 44 79 80 82 80 3 0 2 -25 -2 -55z",
})

export default incidentCatStarBurst