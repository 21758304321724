import GomObjectType from "../../lib/GomObjectType"

const geometCompassArrow = new GomObjectType({
    id: 'arrowCompass',
    type: 'arrowCompass',
    title: 'Cardinal Orientation',
    icon: 'bi bi-compass',
    widthText: true,
    positionText: 'right', // top - right - center - bottom - left
    fieldsText: ['orientation'],
    endTextWidthStr: false,
    angle: 0,
    withBorderColor: true,
    withBorderWidth: true,
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "orientation": {
                "title": "Orientation",
                "description": "Cardinal orientation",
                "type": "string",
                "enum": ["N", "NE", "E", "SE", "S", "SW", "W", "NW"],
                "default": "N"
            }
        },
        "required": ['orientation']
    },
})

export default geometCompassArrow