import GomObjectType from "../../lib/GomObjectType"

const actionAnalysis = new GomObjectType({
    id: 'actionAnalysis',
    type: 'path',
    title: 'Analysis Action',
    icon: 'bi bi-asterisk',
    widthText: true,
    positionText: 'center', // top - right - center - bottom - left
    fieldsText: ["name"],
    endTextWidthStr: "",
    angle: 0,
    withBorderColor: true,
    withBorderWidth: 0,
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "name": {
                "title": "Action name",
                "description": "Action name",
                "type": "string",
            },
        },
        "required": ['name']
    },
    pathData: 'M 4 55 L 100 55 L 120 10 L 180 10 L 200 55 L 280 55 M 280 45 L 295 55 L 280 65 M 280 65 L 280 45 Z',
    svgData: `<svg width="300" height="110" xmlns="http://www.w3.org/2000/svg">

    <line x1="4" y1="55" x2="100" y2="55" stroke="black" stroke-width="4" />
    <line x1="100" y1="55" x2="120" y2="10" stroke="black" stroke-width="4" />
    <line x1="120" y1="10" x2="180" y2="10" stroke="black" stroke-width="4" />
    <line x1="180" y1="10" x2="200" y2="55" stroke="black" stroke-width="4" />
  <line x1="200" y1="55" x2="280" y2="55" stroke="black" stroke-width="4" />
    <polygon points="280 45, 280 65, 295 55" fill="black" />
</svg>`,
})

export default actionAnalysis