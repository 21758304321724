import { fabric } from 'fabric'
// http://fabricjs.com/docs/
import canvasConfig from "../../config/canvas.config"

class GomTextboxShape {

    constructor({ canvas, options }) {
        this.canvas = canvas
        this.options = options

        this.gomObjectType = options.gomObjectType
        this.widthText = options.gomObjectType.widthText
        this.withBorderWidth = options.gomObjectType.withBorderWidth
        this.angle = options.gomObjectType.angle

        this.color = options.color
        this.fill = options.fill
        this.borderCode = options.borderCode
        this.draw()
    }

    draw = () => {
        const text = 'Your text here'
        const textbox = new fabric.Textbox(text, {
            gomObjectType: this.gomObjectType,
            fontSize: canvasConfig.fonstSize + 4,
            left: this.canvas.width / 2,  // Centrar horizontalmente
            top: this.canvas.height / 2,  // Centrar verticalmente
            fill: this.fill,
            originX: 'center',
            originY: 'center',
            editable: true,
            cornerColor: canvasConfig.defaultCornerColor,
        })
        this.canvas.add(textbox)

        this.canvas.requestRenderAll()
    }    
}

export default GomTextboxShape