import GomObjectType from "../../lib/GomObjectType"

const resourcesAircraft = new GomObjectType({
    id: 'resourcesAircraft',
    type: 'path',
    title: 'Aircraft',
    icon: 'bi bi-asterisk',
    widthText: true,
    positionText: 'center', // top - right - center - bottom - left
    fieldsText: ["name"],
    endTextWidthStr: "",
    angle: 0,
    withBorderColor: true,
    withBorderWidth: 0,
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "name": {
                "title": "Resource name",
                "description": "Resource name",
                "type": "string",
            },
        },
        "required": ['name']
    },
    pathData: 'M 10 20 L 10 80 L 100 50 L 10 20 M 190 20 L 100 50 L 190 80 Z',
    svgData: `<svg width="200" height="100" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink">
    <rect x="2" y="2" width="196" height="96" fill="none" stroke="black"></rect>
    <polygon points="100,50 10,20 10,80" stroke="black"></polygon>
    <polygon points="100,50 190,20 190,80" stroke="black"></polygon>
</svg>`,
})

export default resourcesAircraft