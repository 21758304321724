import { fabric } from 'fabric'
// http://fabricjs.com/docs/
import canvasConfig from "../../config/canvas.config"

class GomEllipseShape {
    
    constructor({ canvas, options }) {
        this.canvas = canvas
        this.options = options

        this.gomObjectType = options.gomObjectType
        this.widthText = options.gomObjectType.widthText
        this.withBorderWidth = options.gomObjectType.withBorderWidth
        this.angle = options.gomObjectType.angle

        this.color = options.color
        this.fill = options.fill
        this.borderCode = options.borderCode
        this.draw()
    }

    draw = () => {
        const ellipseIcon = new fabric.Ellipse({
            rx: 50, // Radio en el eje x
            ry: 30, // Radio en el eje y
            fill: this.fill,
            left: this.canvas.width / 2,  // Centrar horizontalmente
            top: this.canvas.height / 2,  // Centrar verticalmente
            originX: 'center',
            originY: 'center',
            cornerColor: canvasConfig.defaultCornerColor,
        })

        ellipseIcon.scaleToWidth(canvasConfig.iconScaleWidth)

        if (!this.widthText) {
            this.canvas.add(ellipseIcon)
        } else {
            const group = this.addToGroupWithText([ellipseIcon], this.fill)
            this.canvas.add(group)
        }

        this.canvas.requestRenderAll()
    }

    addToGroupWithText = (events, textColor) => {
        const text = new fabric.Textbox('', {
            fontSize: canvasConfig.fonstSize,
            fill: textColor,
            originX: 'left',  // left, center, right
            originY: 'center',  // top, center, bottom
            left: events[0].left + 4,
            top: events[0].top,
            editable: true,
            angle: 0,
        })

        const group = new fabric.Group([...events, text], {
            gomObjectType: this.gomObjectType,
            top: text.top,
            left: text.left,
            originX: 'center',
            originY: 'center',
            cornerColor: canvasConfig.defaultCornerColor,
        })

        return group
    }
}

export default GomEllipseShape