import GomObjectType from "../../lib/GomObjectType"

const incidentCatWindow = new GomObjectType({
    id: 'incidentWindow',
    type: 'path',
    title: 'Category 2',
    icon: 'bi bi-microsoft',
    widthText: true,
    positionText: 'right', // top - right - center - bottom - left
    fieldsText: ["name", "victims"],
    endTextWidthStr: "victims",
    angle: 0,
    withBorderColor: true,
    withBorderWidth: 2,
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "name": {
                "title": "Incident",
                "description": "Incident description",
                "type": "string",
            },
            "address": {
                "title": "Address",
                "description": "Incident address",
                "type": "string",
            },
            "victims": {
                "title": "Victims",
                "description": "Incident victims",
                "type": "integer",
            },
        },
        "required": ['name', "address"]
    },
    pathData: "M910 1285 l0 -45 -384 0 -385 0 -15 -22 c-13 -19 -16 -60 -16 -245 l0 -223 -55 0 -55 0 0 -45 0 -45 54 0 55 0 3 -208 c2 -159 6 -213 17 -225 12 -15 53 -17 397 -17 l384 0 0 -105 0 -105 45 0 45 0 0 105 0 105 369 0 c330 0 370 2 382 17 11 12 15 66 17 225 l3 208 125 0 124 0 0 45 0 45 -125 0 -125 0 0 223 c0 185 -3 226 -16 245 l-15 22 -370 0 -369 0 0 45 0 45 -45 0 -45 0 0 -45z m0 -345 l0 -190 -345 0 -345 0 0 190 0 190 345 0 345 0 0 -190z m750 0 l0 -190 -330 0 -330 0 0 190 0 190 330 0 330 0 0 -190z m-750 -450 l0 -170 -345 0 -345 0 0 170 0 170 345 0 345 0 0 -170z m750 0 l0 -170 -330 0 -330 0 0 170 0 170 330 0 330 0 0 -170z",
})

export default incidentCatWindow