import GomObjectType from "../../lib/GomObjectType"

const trafficBypassLine = new GomObjectType({
    id: 'trafficBypass',
    type: 'svg',
    title: 'Bypass',
    icon: 'bi bi-asterisk',
    widthText: true,
    positionText: 'right', // top - right - center - bottom - left
    fieldsText: ["name"],
    endTextWidthStr: "",
    angle: 0,
    withBorderColor: false,
    withBorderWidth: 0,
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "name": {
                "title": "Access name",
                "description": "Access name",
                "type": "string",
            },
        },
        "required": ['name', "address"]
    },
    svgData: `<svg width="300" height="100" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 50 H280" stroke="black" stroke-width="2" fill="none" />
    <path d="M20 60 H280" stroke="black" stroke-width="2" fill="none" />    
</svg>`,
})

export default trafficBypassLine