import GomObjectType from "../../lib/GomObjectType"

const defaultEllipse = new GomObjectType({
    id: 'ellipse',
    type: 'ellipse',
    title: 'Ellipse',
    icon: 'bi bi-hexagon',
    widthText: true,
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    withBorderColor: true,
    withBorderWidth: true,
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
})

export default defaultEllipse