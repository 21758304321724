import GomObjectType from "../../lib/GomObjectType"

const trafficDirectionLine = new GomObjectType({
    id: 'trafficDirection',
    type: 'svg',
    title: 'Traffic Direction',
    icon: 'bi bi-asterisk',
    widthText: true,
    positionText: 'right', // top - right - center - bottom - left
    fieldsText: ["name"],
    endTextWidthStr: "",
    angle: 0,
    withBorderColor: false,
    withBorderWidth: 0,
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "name": {
                "title": "Access name",
                "description": "Access name",
                "type": "string",
            },
        },
        "required": ['name', "address"]
    },
    svgData: `<svg width="300" height="100" xmlns="http://www.w3.org/2000/svg">
    <!-- Línea superior -->
    <line x1="20" y1="50" x2="280" y2="50" stroke="black" stroke-width="2" />
  
    <!-- Línea inferior con mitad de flecha -->
    <line x1="20" y1="70" x2="280" y2="70" stroke="black" stroke-width="2" />
    <polygon points="280,70 270,75 270,70" fill="black" />
  </svg>`,
})

export default trafficDirectionLine