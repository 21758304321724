import { fabric } from 'fabric'
// http://fabricjs.com/docs/fabric.Object.html
// Demos: http://fabricjs.com/demos/

import GomObjectType from './GomObjectType'

// Default objects
import GomTextboxShape from '../components/gomObjectShapes/GomTextbox.shape'
import GomArrowShape from '../components/gomObjectShapes/GomArrow.shape'
import GomLineShape from '../components/gomObjectShapes/GomLine.shape'
import GomPolyLineShape from '../components/gomObjectShapes/GomPolyLine.shape'
import GomTriangleShape from '../components/gomObjectShapes/GomTriangle.shape'
import GomRectangleShape from '../components/gomObjectShapes/GomRectangle.shape'
import GomCircleShape from '../components/gomObjectShapes/GomCircle.shape'
import GomEllipseShape from '../components/gomObjectShapes/GomEllipse.shape'
import GomPolygonShape from '../components/gomObjectShapes/GomPolygon.shape'

// Geospatial & meteo objects
import GomCompassShape from '../components/gomObjectShapes/GomCompass.shape'
import GomWindShape from '../components/gomObjectShapes/GomWind.shape'

// Incident
import GomStarShape from '../components/gomObjectShapes/GomStar.shape'

// Path and SVG objects
import GomPathShape from '../components/gomObjectShapes/GomPath.shape'
import GomSvgShape from '../components/gomObjectShapes/GomSvg.shape'

// Brush draw parameters config
import brushDraw from '../config/gomObjectTypes/brushDraw'

// Canvas default config
import canvasConfig from '../config/canvas.config'

// Extiende la clase PencilBrush
class CustomPencilBrush extends fabric.PencilBrush {
    constructor(canvas) {
        super(canvas)
        // Añade tu propiedad personalizada
        this.drawType = 'freehand'
    }
}

class GomCanvas {
    constructor(canvasRef, opts) {
        if (!canvasRef || !canvasRef.current) {
            console.error("Invalid canvas reference!")
            return
        }
        this.canvas = new fabric.Canvas(canvasRef.current)
        this.width = this.canvas.width
        this.height = this.canvas.height
        this.pencilBrush = null
        this.eraserBrush = null
        this.isDragging = false
        this.lastPosX = 0
        this.lastPosY = 0
        this.drawnPaths = []

    }

    // Set canvas options
    setOptions = (opts) => {
        this.canvas.set(opts)

        // Set grid
        if (opts.grid) {
            this.setGrid(opts.grid)
        }

        // Set default events
        this.setEvents()
    }

    // Set canvas dimensions
    setDimensions = ({ width = this.width, height = this.height }) => this.canvas.setDimensions({ width: width, height: height })

    // Set events on
    setEvents = () => {
        this.canvas.on('backgroundImage:changed', (e) => {
            console.log('Se activó el evento personalizado "miEvento" en el rectángulo.', e)
        })
    }

    // Set pencilbrush
    setPencilBrush = ({ color = 'black', width = 2, strokeWidth = 0, strokeDashArray = [], strokeUniform = true }) => {
        this.pencilBrush = new CustomPencilBrush(this.canvas)
        this.pencilBrush.color = color
        this.pencilBrush.width = width
        this.pencilBrush.strokeDashArray = strokeDashArray
        this.pencilBrush.strokeUniform = strokeUniform
    }

    // Set background with grid
    setGrid = (gridSize) => {
        if (gridSize > 0) {
            const grid = gridSize;
            const unitScale = 2;
            const canvasWidth = this.width * unitScale;
            const canvasHeight = this.height * unitScale;

            let bgLines = []
            // create grid            
            for (let i = 0; i <= canvasWidth / grid; i++) {
                bgLines.push(new fabric.Line([i * grid, 0, i * grid, canvasHeight], { type: 'line', gomObjectType: 'background-line', stroke: '#ccc', selectable: false, hoverCursor: 'default' }))
            }

            for (let j = 0; j <= canvasHeight / grid; j++) {
                bgLines.push(new fabric.Line([0, j * grid, canvasWidth, j * grid], { type: 'line', gomObjectType: 'background-line', stroke: '#ccc', selectable: false, hoverCursor: 'default' }))
            }

            const bgGroup = new fabric.Group(bgLines, {
                gomObjectType: 'background-grid', // Tipo de objeto
                top: 0,
                left: 0,
                selectable: false,
                hoverCursor: 'default',
                //originX: 'center',
                //originY: 'center',
            })

            this.canvas.add(bgGroup)
            bgGroup.sendToBack()
        } else {
            const allObjects = this.getObjects()
            const groupObjects = allObjects.filter(obj => obj.gomObjectType === 'background-grid')
            groupObjects.forEach(group => this.canvas.remove(group))
        }
        this.getRequestRenderAll()
    }

    setFromJSON = (jsonObject) => {
        this.canvas.loadFromJSON(jsonObject, this.getRequestRenderAll, (object) => {
            const myobject = {
                ...object,
                loadedFromJSON: true,
            }
            console.log("cargandooo", myobject)
            return myobject
        })
    }

    // Get canvas
    getCanvas = () => (this.canvas)

    // Get canvas width
    getWidth = () => (this.canvas.width)

    // Get canvas height
    getHeight = () => (this.canvas.height)

    // Request render all
    getRequestRenderAll = () => this.canvas.requestRenderAll()

    // Render all
    getRenderAll = () => this.canvas.renderAll()

    // Dispose close canvas
    getDispose = () => this.canvas.dispose()

    // Get canvas in PNG or JPG image format
    getCanvasImage = ({ imageFormat = 'png', imageQuality = 1, multiplier = 3 }) => {
        const canvasDataUrl = this.canvas.toDataURL({
            format: imageFormat, // especifica el formato como png
            quality: imageQuality, // calidad (de 0 a 1)
            multiplier: multiplier, // Resolution
        })

        const link = document.createElement('a')
        link.href = canvasDataUrl
        link.download = 'canvas_image.png'
        link.click()
        URL.revokeObjectURL(link.href)
    }

    // Get canvas in JSON format
    getCanvasJSON = () => {
        const canvasJSON = this.toJSON()
        const jsonString = JSON.stringify(canvasJSON)

        const blob = new Blob([jsonString], { type: 'application/json' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'canvas.json'
        link.click()
        URL.revokeObjectURL(link.href)
    }

    // Canvas to JSON format
    toJSON = () => this.canvas.toJSON(['gomObjectType', 'timestamp', 'createdAt', 'selectable', 'hoverCursor'])

    // Get zoom level
    getZoom = () => this.canvas.getZoom()

    // Get active object/s
    getActiveObject = () => this.canvas.getActiveObject()
    getActiveObjects = () => this.canvas.getActiveObjects()

    // Get all objects
    getObjects = () => this.canvas.getObjects()

    // Discard Active Object
    discardActiveObject = () => this.canvas.discardActiveObject()

    // Add on events on
    addOn = (eventName, eventFunction) => {
        this.canvas.on(eventName, eventFunction)
    }

    // Remove on  events off
    addOff = (eventName) => {
        this.canvas.off(eventName)
    }

    // Add a fabric object
    add = (fabricObject) => {
        this.canvas.add(fabricObject)
    }

    // Add a object
    addObject = (object) => {
        fabric.Group.fromObject(object, (group) => {
            this.add(group)
            this.getRequestRenderAll()
        })
    }

    // Add a defined object
    addGomObject = ({
        gomObjectType,
        color,
        fill,
        borderCode
    }) => {

        gomObjectType.timestamp = Date.now()
        const objOptions = {
            gomObjectType: gomObjectType,
            color: color,
            fill: fill,
            borderCode: borderCode
        }

        switch (gomObjectType.type) {
            case 'arrowCompass':
                new GomCompassShape({ canvas: this.canvas, options: objOptions })
                break
            case 'arrowWind':
                new GomWindShape({ canvas: this.canvas, options: objOptions })
                break
            case 'textbox':
                new GomTextboxShape({ canvas: this.canvas, options: objOptions })
                break
            case 'line':
                new GomLineShape({ canvas: this.canvas, options: objOptions })
                break
            case 'polyline':
                new GomPolyLineShape({ canvas: this.canvas, options: objOptions })
                break
            case 'arrow':
                new GomArrowShape({ canvas: this.canvas, options: objOptions })
                break
            case 'triangle':
                new GomTriangleShape({ canvas: this.canvas, options: objOptions })
                break
            case 'rectangle':
                new GomRectangleShape({ canvas: this.canvas, options: objOptions })
                break
            case 'circle':
                new GomCircleShape({ canvas: this.canvas, options: objOptions })
                break
            case 'ellipse':
                new GomEllipseShape({ canvas: this.canvas, options: objOptions })
                break
            case 'polygon':
                new GomPolygonShape({ canvas: this.canvas, options: objOptions })
                break
            case 'star':
                new GomStarShape({ canvas: this.canvas, options: objOptions })
                break
            case 'path':
                new GomPathShape({ canvas: this.canvas, options: objOptions })
                break
            case 'svg':
                new GomSvgShape({ canvas: this.canvas, options: objOptions })
                break
            default:
                break
        }
        this.getRequestRenderAll()
    }

    // Delete all objects
    deleteAll = () => {
        this.canvas.clear()
    }

    // Delete selected object
    deleteSelected = () => {
        const object = this.getActiveObject()
        if (object) {
            this.canvas.remove(object)
            this.getRequestRenderAll()
        }
    }

    // Set canvas draggable
    setDraggable = (draggable) => {
        if (draggable) {
            // Manejar el inicio del arrastre
            this.canvas.on('mouse:down', (event) => {
                this.isDragging = true
                this.lastPosX = event.e.clientX
                this.lastPosY = event.e.clientY
            })

            // Manejar el movimiento durante el arrastre
            this.canvas.on('mouse:move', (event) => {
                if (this.isDragging) {
                    let deltaX = event.e.clientX - this.lastPosX
                    let deltaY = event.e.clientY - this.lastPosY

                    // Ajustar la posición del lienzo basándote en el desplazamiento
                    this.canvas.relativePan(new fabric.Point(deltaX, deltaY))

                    this.lastPosX = event.e.clientX
                    this.lastPosY = event.e.clientY

                    // Renderizar el lienzo
                    this.getRenderAll()
                }
            })

            // Manejar el fin del arrastre
            this.canvas.on('mouse:up', () => {
                this.isDragging = false
            })

            this.centerCanvas(this.getCenterWithZoom())

        } else {
            this.canvas.off('mouse:down')
            this.canvas.off('mouse:move')
            this.canvas.off('mouse:up')
        }
    }

    // Función para centrar el canvas
    centerCanvas = (centerParams) => {
        const canvasWidth = this.canvas.width
        const canvasHeight = this.canvas.height

        // Si se proporcionan parámetros, usarlos para el centro
        const centerX = centerParams ? centerParams.x - canvasWidth / 2 : window.innerWidth / 2 - canvasWidth / 2
        const centerY = centerParams ? centerParams.y - canvasHeight / 2 : window.innerHeight / 2 - canvasHeight / 2

        this.canvas.absolutePan(new fabric.Point(centerX, centerY))
        this.getRenderAll()
    }

    getCenterWithZoom = () => {
        const objects = this.getObjects()

        if (objects.length === 0) {
            return { x: 0, y: 0 } // Si no hay objetos, el centro es el origen
        }

        // Inicializar valores extremos para el bounding box
        let minX = Number.MAX_VALUE
        let minY = Number.MAX_VALUE
        let maxX = Number.MIN_VALUE
        let maxY = Number.MIN_VALUE

        // Calcular el bounding box que rodea todos los objetos
        objects.forEach(obj => {
            const objBoundingBox = obj.getBoundingRect()
            minX = Math.min(minX, objBoundingBox.left)
            minY = Math.min(minY, objBoundingBox.top)
            maxX = Math.max(maxX, objBoundingBox.left + objBoundingBox.width)
            maxY = Math.max(maxY, objBoundingBox.top + objBoundingBox.height)
        })

        // Obtener el centro del bounding box
        const centerX = (minX + maxX)
        const centerY = (minY + maxY)

        // Obtener el factor de zoom actual del canvas
        const zoomFactor = this.canvas.getZoom()

        // Calcular el centro ajustado por el zoom
        const adjustedCenterX = centerX * zoomFactor
        const adjustedCenterY = centerY * zoomFactor

        return { x: adjustedCenterX, y: adjustedCenterY }
    }

    setObjectProperties = (canvasObject, properties) => {
        if (canvasObject && canvasObject.type === 'group') {
            canvasObject.forEachObject((canvasObject) => {
                if (canvasObject && canvasObject.type !== 'group' && canvasObject.type !== 'textbox') {
                    canvasObject.set(properties)
                } else if (canvasObject && canvasObject.type === 'textbox' && properties.stroke) {
                    canvasObject.set({ fill: properties.stroke })
                } else if (canvasObject && canvasObject.type === 'group') {
                    canvasObject.forEachObject((group) => {
                        if (properties.stroke) {
                            group.set({
                                stroke: properties.stroke,
                            })
                        } else {
                            group.set({
                                fill: properties.fill,
                            })
                        }
                    })
                }
            })
        } else if (canvasObject && canvasObject.type !== 'textbox') {
            canvasObject.set(properties)
        } else if (canvasObject && canvasObject.type === 'textbox' && properties.stroke) {
            canvasObject.set({ fill: properties.stroke })
        }
    }

    // Set color fill to selected object
    setFillToSelected = ({ fill = '#000000' }) => {
        const activeObject = this.getActiveObject()
        this.setObjectProperties(activeObject, { fill })
        this.getRequestRenderAll()
        this.canvas.fire('object:modified', { action: "changeColor", target: activeObject })
    }

    // Set color to selected object
    setColorToSelected = ({ color = '#000000' }) => {
        const group = this.getActiveObject()
        this.setObjectProperties(group, { stroke: color })
        this.getRequestRenderAll()
        this.canvas.fire('object:modified', { action: "changeColor", target: group })
    }

    // Set border to selected object
    setBorderToSelected = ({ borderCode, colorCode }) => {
        const properties = {
            strokeWidth: borderCode.strokeWidth,
            strokeDashArray: borderCode.strokeDashArray,
            stroke: colorCode.color,
            fill: 'transparent',
        }
        const group = this.getActiveObject()
        this.setObjectProperties(group, properties)
        this.getRequestRenderAll()
        this.canvas.fire('object:modified', { action: "changeBorder", target: group })
    }

    // Set background image
    setBackgroundImage = (dataUrl) => {
        if (dataUrl) {
            fabric.Image.fromURL(dataUrl, (oImg) => {
                oImg.scaleToWidth(this.canvas.width)
                //oImg.scaleToHeight(this.canvas.height)
                oImg.set({
                    originX: 'center',
                    originY: 'center',
                    left: this.canvas.width / 2,
                    top: this.canvas.height / 2,
                })
                //oImg.scale(this.canvas.width, this.canvas.height)
                this.canvas.setBackgroundImage(
                    oImg,
                    () => {
                        this.canvas.fire('backgroundImage:added', oImg)
                        this.getRequestRenderAll()
                    },
                    {
                        opacity: 0.5,
                        //angle: 45,
                    }
                )
            })
        }
    }

    // Set text to selected object if have a textbox object
    setText = (text) => {
        const activeObject = this.getActiveObject()
        if (activeObject && activeObject.type === 'group') {
            const group = activeObject._objects
            group.map((object) => {
                if (object && object.type === 'textbox') {
                    object.set({ text: text })
                }
            })
        } else if (activeObject && activeObject.type === 'textbox') {
            activeObject.set({ text: text })
        }
        this.getRequestRenderAll()
        this.canvas.fire('object:modified', { action: "changeText", target: activeObject })
    }

    // Set zoom level
    setZoom = (zoom) => {
        this.canvas.setZoom(zoom)
        this.getRenderAll()
    }

    // Bring selected object to from
    bringToFront = () => {
        const activeObject = this.getActiveObject()
        this.canvas.bringToFront(activeObject)
        this.discardActiveObject()
        this.getRequestRenderAll()
    }

    // Send selected object to back
    sendToBack = () => {
        const activeObject = this.getActiveObject()
        //this.canvas.sendToBack(activeObject)
        this.canvas.moveTo(activeObject, 1)
        this.discardActiveObject()
        this.getRequestRenderAll()
    }

    // Lock movement
    lockMovement = (lock = true) => {
        const activeObject = this.getActiveObject()
        activeObject.set({
            selectable: true,
            lockMovementX: lock,
            lockMovementY: lock,
            lockRotation: lock,
            lockScalingX: lock,
            lockScalingY: lock,
        })
        this.getRequestRenderAll()
    }

    // Start/Stop drawing mode
    setDrawMode = ({ active = false }) => {
        if (active) {
            // Set the pencil brush as the active drawing tool
            this.canvas.isDrawingMode = true
            this.canvas.freeDrawingBrush = this.pencilBrush
            this.drawnPaths = []

            this.canvas.on('path:created', (e) => {
                this.drawnPaths.push(e.path)
            })
        } else {
            // Clear the active drawing tool
            this.canvas.isDrawingMode = false
            this.canvas.freeDrawingBrush = null
            this.canvas.off('path:created')
            const drawObjectOptions = {
                ...brushDraw,
                timestamp: Date.now(),
            }
            const text = new fabric.Textbox('', {
                fontSize: canvasConfig.fonstSize,
                fill: this.pencilBrush.color,
                originX: 'center',  // left, center, right
                originY: 'center',  // top, center, bottom
                left: this.drawnPaths[0].left,
                top: this.drawnPaths[0].top,
                editable: true,
                angle: 0,
            })
            const group = new fabric.Group(this.drawnPaths, {
                selectable: true,
                type: 'group',
                gomObjectType: new GomObjectType(drawObjectOptions),
                cornerColor: canvasConfig.defaultCornerColor,
            })
            group.addWithUpdate(text)
            this.canvas.remove(text)
            this.drawnPaths.map((path) => {
                this.canvas.remove(path)
            })
            this.canvas.add(group)
            this.drawnPaths = []

            this.getRequestRenderAll()
        }
    }
}

export default GomCanvas