import GomObjectType from "../../lib/GomObjectType"

const resourceInfrastructureRect = new GomObjectType({
    id: 'resourceInfrastructure',
    type: 'path',
    title: 'Infrastructure & Logistic',
    icon: 'bi bi-asterisk',
    widthText: true,
    positionText: 'right', // top - right - center - bottom - left
    fieldsText: ["type"],
    endTextWidthStr: "",
    angle: 0,
    withBorderColor: true,
    withBorderWidth: 0,
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "name": {
                "title": "Name",
                "description": "Infrastructure or Logistic name",
                "type": "string",
            },
            "type": {
                "title": "Type",
                "description": "Infrastructure or Logistic type",
                "type": "string",
                "enum": [
                    "CRM",
                    "PRV",
                    "P",
                    "PmeA",
                    "HOSP",
                    "H",
                    "PSY",
                    "VET",
                    "PHAR",
                    "MEC",
                    "OIL",
                    "ELEC",
                    "ESPUM",
                    "OTRO",
                ]
            }
        },
        "required": ['name', 'type']
    },
    pathData: 'M10 30 L190 30 L190 110 L10 110 L10 30 M10 15 L190 15',
    svgData: `<svg width="200" height="120" xmlns="http://www.w3.org/2000/svg">
    <!-- Rectángulo con bordes curvos -->
    <rect x="10" y="30" width="180" height="80" rx="5" ry="5" fill="none" stroke="black" stroke-width="2" />
  
    <!-- Línea horizontal encima -->
    <line x1="10" y1="15" x2="190" y2="15" stroke="black" stroke-width="2" />
  </svg>`,
})

export default resourceInfrastructureRect