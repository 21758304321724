import React, { useState } from 'react'
import { ButtonGroup, Button, Popover, Row, OverlayTrigger, DropdownButton, Dropdown } from 'react-bootstrap'

import gomObjectTypes from '../config/gomObjectTypes.config'

const ObjectPicker = ({ onAddObject }) => {
    const [selectedObject, setSelectedObject] = useState(null)
    const [showOverlay, setShowOverlay] = useState(false)

    const handleAddObjectSelection = (object) => {
        setSelectedObject(object)
        onAddObject(object)
        setShowOverlay(false)
    }

    const handleToggleOverlay = () => {
        setShowOverlay(!showOverlay)
    }

    const renderObjects = () => {
        return (
            <ButtonGroup size='lg' className="me-2" aria-label="Insert Objects" vertical>
                {
                    gomObjectTypes.map((objectType, index) => {
                        if (objectType.objects.length === 1) {
                            return (
                                <Button
                                    key={'btn-object-type-' + objectType.id}
                                    variant='secondary'
                                    size='lg'
                                    onClick={() => handleAddObjectSelection(objectType.objects[0])}
                                    title={objectType.title}
                                >
                                    <i className={objectType.icon} /> {objectType.title}
                                </Button>
                            )
                        } else {
                            return (
                                <DropdownButton
                                    key={'dropdownbtn-object-type-' + objectType.id}
                                    variant='secondary'
                                    as={ButtonGroup}
                                    size={'lg'}
                                    title={<><i className={objectType.icon} /> {objectType.title}</>}
                                >
                                    {objectType.objects.map((object, index2) => {
                                        return (
                                            <Dropdown.Item
                                                key={'dropdownbtn-object-type-' + objectType.id + '-object-' + index2}
                                                eventKey={index + 1}
                                                className="d-flex align-items-center"
                                                onClick={() => handleAddObjectSelection(object)}
                                                style={{ fontSize: '20px', height: 50 }}
                                            >
                                                <i className={object.icon} />&nbsp; {object.title}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </DropdownButton>
                            )
                        }

                    })
                }
            </ButtonGroup>
        )
    }

    const objectPickerPopover = (
        <Popover id="object-picker-popover" style={{ maxWidth: '600px', fontSize: '1.25rem' }}>
            <Popover.Header className="d-flex justify-content-between align-items-center">
                <i className="bi bi-columns-gap"></i> Add an object
            </Popover.Header>
            <Popover.Body>
                <Row>
                    {renderObjects()}
                </Row>
            </Popover.Body>
        </Popover>
    )

    return (
        <OverlayTrigger trigger="click" placement="bottom" overlay={objectPickerPopover} show={showOverlay}>
            <Button variant={showOverlay ? 'primary' : 'secondary'} size='lg' onClick={handleToggleOverlay}>
                <i className="bi bi-columns-gap"></i>
            </Button>
        </OverlayTrigger>
    )
}

export default ObjectPicker
