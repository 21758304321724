import GomObjectType from "../../lib/GomObjectType"

const defaultArrow = new GomObjectType({
    id: 'arrow',
    type: 'arrow',
    title: 'Arrow',
    icon: 'bi bi-arrow-up',
    widthText: true,
    positionText: 'right', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: false,
    angle: 0,
    withBorderColor: false,
    withBorderWidth: true,
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Arrow text",
                "type": "string",
            }
        },
        "required": ['title']
    },
})

export default defaultArrow