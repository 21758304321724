import GomObjectType from "../../lib/GomObjectType"

const actionOffensive = new GomObjectType({
    id: 'actionOffensive',
    type: 'arrow',
    title: 'Offensive Action',
    icon: 'bi bi-asterisk',
    widthText: true,
    positionText: 'center', // top - right - center - bottom - left
    fieldsText: ["name"],
    endTextWidthStr: "",
    angle: 0,
    withBorderColor: true,
    withBorderWidth: 0,
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "name": {
                "title": "Action name",
                "description": "Action name",
                "type": "string",
            },
        },
        "required": ['name']
    },
})

export default actionOffensive