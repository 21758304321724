import { fabric } from 'fabric'
// http://fabricjs.com/docs/
import canvasConfig from "../../config/canvas.config"

class GomArrowShape {
   
    constructor({ canvas, options }) {
        this.canvas = canvas
        this.options = options

        this.gomObjectType = options.gomObjectType
        this.widthText = options.gomObjectType.widthText
        this.withBorderWidth = options.gomObjectType.withBorderWidth
        this.angle = options.gomObjectType.angle

        this.color = options.color
        this.fill = options.fill
        this.borderCode = options.borderCode
        this.draw()
    }

    draw = () => {
        const lineCoords = [0, 0, 0, canvasConfig.defaultHeight]

        var lineIcon = new fabric.Line(lineCoords, {
            left: this.canvas.width / 2,
            top: this.canvas.height / 2,
            stroke: this.fill,
            strokeWidth: this.borderCode.strokeWidth,
            strokeDashArray: this.borderCode.strokeDashArray,
            angle: this.angle,
            originX: 'left',  // left, center, right
            originY: 'center',  // top, center, bottom
        })

        var triangleIcon = new fabric.Triangle({
            width: 12,
            height: 15,
            fill: this.fill,
            left: lineIcon.left - 5.5,
            top: lineIcon.top - lineIcon.height / 2,  // Posicionar arriba de la línea
            angle: this.angle,
            originX: 'left',  // left, center, right
            originY: 'center',  // top, center, bottom
        })

        if (!this.widthText) {
            var arrowObj = new fabric.Group([lineIcon, triangleIcon], {
                gomObjectType: this.gomObjectType,
                top: lineIcon.top,
                left: lineIcon.left,
                originX: 'center',
                originY: 'center',
                cornerColor: canvasConfig.defaultCornerColor,
            })
            this.canvas.add(arrowObj)
        } else {
            const group = this.addToGroupWithText([lineIcon, triangleIcon], this.fill)
            this.canvas.add(group)
        }

        this.canvas.requestRenderAll()
    }

    addToGroupWithText = (events, textColor) => {
        const text = new fabric.Textbox('', {
            fontSize: canvasConfig.fonstSize,
            fill: textColor,
            originX: 'left',  // left, center, right
            originY: 'center',  // top, center, bottom
            left: events[0].left + 4,
            top: events[0].top,
            editable: true,
            angle: 0,
        })

        const group = new fabric.Group([...events, text], {
            gomObjectType: this.gomObjectType,
            top: text.top,
            left: text.left,
            originX: 'center',
            originY: 'center',
            cornerColor: canvasConfig.defaultCornerColor,
        })

        return group
    }
}

export default GomArrowShape