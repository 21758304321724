import GomObjectType from "../../lib/GomObjectType"

const trafficAccessLine = new GomObjectType({
    id: 'trafficAccess',
    type: 'path',
    title: 'Access',
    icon: 'bi bi-asterisk',
    widthText: true,
    positionText: 'right', // top - right - center - bottom - left
    fieldsText: ["name"],
    endTextWidthStr: "",
    angle: 0,
    withBorderColor: true,
    withBorderWidth: 0,
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "name": {
                "title": "Access name",
                "description": "Access name",
                "type": "string",
            },
        },
        "required": ['name', "address"]
    },
    pathData: 'M20 50 H280 M230 60 A5 5 0 1 0 240 60 A5 5 0 1 0 230 60 Z M250 60 A5 5 0 1 0 260 60 A5 5 0 1 0 250 60 Z M270 60 A5 5 0 1 0 280 60 A5 5 0 1 0 270 60 Z',
    svgData: `<svg width="300" height="100" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 50 H280" stroke="black" stroke-width="2" fill="none" />
    
    <circle cx="230" cy="60" r="5" fill="black" />
    <circle cx="250" cy="60" r="5" fill="black" />
    <circle cx="270" cy="60" r="5" fill="black" />
</svg>`,
})

export default trafficAccessLine