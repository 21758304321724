import GomObjectType from "../../lib/GomObjectType"

const defaultTextbox = new GomObjectType({
    id: 'textbox',
    type: 'textbox',
    title: 'Text box',
    icon: 'bi bi-textarea-t',
    widthText: true,
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    withBorderColor: false,
    withBorderWidth: false,
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
})

export default defaultTextbox