import React, { useEffect, useRef, useState } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'

import moment from 'moment'

const InfoModal = ({ show, element, onUpdate, onClose }) => {
    const { title, description, icon, schema, formData, timestamp, createdAt } = element.gomObjectType

    const formRef = useRef()
    const [formDataValues, setFormDataValues] = useState(formData)
    const [formValidated, setFormValidated] = useState(false)

    const handleOnUpdate = (event) => {
        if (formRef.current.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        } else {
            onUpdate(formDataValues)
        }
        setFormValidated(true);
    }

    const handleOnClose = () => {
        onClose()
    }

    const handleFieldValue = (e) => {
        setFormDataValues((prevFormDataValues) => ({
            ...prevFormDataValues,
            [e.target.name]: e.target.value
        }))
    }

    const RequiredAsterisk = ({ required }) => {
        if (required) {
            return <>*</>
        }
    }

    const InfoDescription = ({ description }) => {
        return <i className="bi bi-info-circle" title={description}></i>
    }

    const checkDefaultValue = (fieldName, fieldData, defaultValue) => {
        if (defaultValue && fieldData === '') {
            setFormDataValues((prevFormDataValues) => ({
                ...prevFormDataValues,
                [fieldName]: defaultValue
            }))
            return defaultValue
        }
        return fieldData
    }

    const renderTextField = ({ key, fieldSchema, fieldData = '', required }) => (
        <Form.Group key={'input-' + key} className="mb-3" controlId={key}>
            <Form.Label><InfoDescription description={fieldSchema.description} /> {fieldSchema.title}:<RequiredAsterisk required={required} /></Form.Label>
            <Form.Control
                name={key}
                value={checkDefaultValue(key, fieldData, fieldSchema.default)}
                type={fieldSchema.type}
                onChange={handleFieldValue}
                required={required ? 'required' : ''}
            />
            <Form.Control.Feedback type="invalid">{fieldSchema.description}</Form.Control.Feedback>
        </Form.Group>
    )

    const renderSelectField = ({ key, fieldSchema, fieldData = '', required }) => (
        <Form.Group key={'input-' + key} className="mb-3" controlId={key}>
            <Form.Label><InfoDescription description={fieldSchema.description} /> {fieldSchema.title}:<RequiredAsterisk required={required} /></Form.Label>
            <Form.Select
                name={key}
                value={checkDefaultValue(key, fieldData, fieldSchema.default)}
                type={fieldSchema.type}
                onChange={handleFieldValue}
                required={required ? 'required' : ''}
            >
                <option></option>
                {fieldSchema.enum.map((enumValue) => (
                    <option key={key + '-select-' + enumValue} value={enumValue}>{enumValue}</option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{fieldSchema.description}</Form.Control.Feedback>
        </Form.Group>
    )

    const renderNumberField = ({ key, fieldSchema, fieldData = '', required }) => (
        <Form.Group key={'input-' + key} className="mb-3" controlId={key}>
            <Form.Label><InfoDescription description={fieldSchema.description} /> {fieldSchema.title}:<RequiredAsterisk required={required} /></Form.Label>
            <Form.Control
                name={key}
                value={checkDefaultValue(key, fieldData, fieldSchema.default)}
                type={'number'}
                onChange={handleFieldValue}
                required={required ? 'required' : ''}
            />
            <Form.Control.Feedback type="invalid">{fieldSchema.description}</Form.Control.Feedback>
        </Form.Group>
    )

    const renderField = ({ key, fieldSchema, fieldData, required }) => {
        switch (fieldSchema.type) {
            case 'string':
                if (fieldSchema.enum) {
                    return renderSelectField({ key, fieldSchema, fieldData, required })
                }
                return renderTextField({ key, fieldSchema, fieldData, required })
            case 'integer':
            case 'number':
                return renderNumberField({ key, fieldSchema, fieldData, required })
            default:
                return renderTextField({ key, fieldSchema, fieldData, required })
        }
    }

    const renderFields = (fieldsSchema, fieldsData) => {
        const requiredFields = fieldsSchema.required
        const formFields = Object.keys(fieldsSchema.properties).map((key) => (
            renderField({
                key,
                fieldSchema: fieldsSchema.properties[key],
                fieldData: fieldsData[key],
                required: requiredFields.some((field) => (field === key))
            })
        ))
        return formFields
    }

    return (
        <Modal show={show} size="lg" centered onHide={handleOnClose} >
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className={`mr-2 ${icon}`}></i> {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{description}</p>
                <Form ref={formRef} noValidate validated={formValidated}>
                    {renderFields(schema, formDataValues)}

                    <Form.Group className="mb-3" controlId="form.timestamp">
                        <Form.Label>Updated:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder=""
                            value={moment(timestamp).fromNow()}
                            readOnly
                            plaintext
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="form.timestamp">
                        <Form.Label>Created:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder=""
                            value={moment(createdAt).fromNow()}
                            readOnly
                            plaintext
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleOnClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleOnUpdate}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default InfoModal