import GomObjectType from "../../lib/GomObjectType"

const resourceCommandPostRect = new GomObjectType({
    id: 'resourceCommandPost',
    type: 'path',
    title: 'Command Post',
    icon: 'bi bi-asterisk',
    widthText: true,
    positionText: 'center', // top - right - center - bottom - left
    fieldsText: ["name"],
    endTextWidthStr: "",
    angle: 0,
    withBorderColor: true,
    withBorderWidth: 0,
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "name": {
                "title": "Resource name",
                "description": "Resource name",
                "type": "string",
            },
        },
        "required": ['name']
    },
    pathData: 'M4 4 L174 4 L174 104 L4 104 Z M4 104 L4 165',
    svgData: `<svg width="180" height="170" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="170" height="100" stroke="black" fill="none" stroke-width="4" />
    <line x1="4" y1="104" x2="4" y2="165" stroke="black" stroke-width="4" />
  </svg>`,
})

export default resourceCommandPostRect