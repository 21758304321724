import React, { useState } from 'react'
import { ButtonGroup, Button } from 'react-bootstrap'

const ZoomControls = ({ onZoomChange, onGrid }) => {
    const [zoom, setZoom] = useState(1)
    const [btnZoomOutDisabled, setBtnZoomOutDisabled] = useState(false)
    const [btnZoomInDisabled, setBtnZoomInDisabled] = useState(false)
    const [grid, setGrid] = useState(true)

    const handleZoom = (newZoom) => {
        // Limitar el rango del zoom
        newZoom = Math.min(1.9, Math.max(0.1, newZoom))

        // Actualizar el estado del zoom
        setZoom(newZoom)
        onZoomChange(newZoom)

        // Deshabilitar/enabled botones según el rango del zoom
        setBtnZoomOutDisabled(newZoom <= 0.1)
        setBtnZoomInDisabled(newZoom >= 1.9)
    }

    const handleZoomOut = () => {
        handleZoom(zoom - 0.1)
    }

    const handleZoomIn = () => {
        handleZoom(zoom + 0.1)
    }

    const handleResetZoom = () => {
        handleZoom(1)
    }

    const handleGrid = () => {
        onGrid(!grid)
        setGrid(!grid)
    }

    return (
        <ButtonGroup size='lg' className="me-2" aria-label="Zoom Controls">
            <Button variant='secondary' size='lg' onClick={handleZoomOut} title='Zoom out' disabled={btnZoomOutDisabled ? 'disabled' : ''}>
                <i className="bi bi-zoom-out"></i>
            </Button>
            <Button variant='secondary' size='lg' onClick={handleZoomIn} title='Zoom in' disabled={btnZoomInDisabled ? 'disabled' : ''}>
                <i className="bi bi-zoom-in"></i>
            </Button>
            <Button variant='secondary' size='lg' onClick={handleResetZoom} title='Reset zoom'>
                <i className="bi bi-search"></i><small>{parseInt(zoom * 100 / 1)}%</small>
            </Button>
            <Button variant={grid ? 'primary' : 'secondary'} size='lg' onClick={handleGrid} title='View grid'>
                <i className="bi bi-grid-3x3"></i>
            </Button>
        </ButtonGroup>
    )
}

export default ZoomControls
