import React, { useEffect, useState } from 'react'
import { Card, Tabs, Tab, Row, Col, Form } from 'react-bootstrap'

import moment from 'moment'

import useGomDB from './canvas/hooks/useGomDB'

//import DrawingCanvas from './pages/DrawingCanvas'
import Canvas from './canvas/Canvas'
import mapaAntiguo from './pages/mapa_mundo_antiguo.jpg'

const App = () => {
  const { lastRecord, updateDate, addRecord, clearAllRecords, deleteDatabase } = useGomDB()

  const [tabKey, setTabKey] = useState("sitac")

  const handleOnSelection = (event) => {
    const selectedObject = event
    console.log('APP -> Objeto seleccionado:', selectedObject)
  }

  const handleOnAdd = (canvasJSON, event) => {
    addRecord(canvasJSON)
    //handleDeleteDatabase()
    const selectedObject = event.target
    console.log('APP -> Objeto añadido:', selectedObject)
  }

  const handleOnChange = (canvasJSON, event) => {
    addRecord(canvasJSON)

    const selectedObject = event
    console.log('APP -> Objeto cambiado:', selectedObject)
  }

  const handleOnDeleteAll = () => {
    clearAllRecords()
  }

  const handleBackgroundChange = (event) => {
    const selectedObject = event
    console.log('APP -> Background image cambiada:', selectedObject)
  }

  return (
    <Tabs
      activeKey={tabKey}
      onSelect={(k) => setTabKey(k)}
      className="mb-3"
    >
      <Tab eventKey="sitac" title="SITAC">
        <Canvas
          width={1250}
          height={650}
          selection={false}
          onAdd={handleOnAdd}
          onSelection={handleOnSelection}
          onChange={handleOnChange}
          onDeleteAll={handleOnDeleteAll}
          backGroundImage={mapaAntiguo}
          onBackgroundChange={handleBackgroundChange}
          canvasJSON={lastRecord}
        />
      </Tab>
      <Tab eventKey="soiem" title="SOIEM">
        <Row className='bg-warning'>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Situación</Form.Label>
              <Form.Control as="textarea" rows={8} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Anticipación</Form.Label>
              <Form.Control as="textarea" rows={8} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Objetivos</Form.Label>
              <Form.Control as="textarea" rows={8} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Idea de maniobra</Form.Label>
              <Form.Control as="textarea" rows={8} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Ejecución (sectores)</Form.Label>
              <Form.Control as="textarea" rows={8} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Logística</Form.Label>
              <Form.Control as="textarea" rows={8} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Mando</Form.Label>
              <Form.Control as="textarea" rows={8} />
            </Form.Group>
          </Col>
        </Row>
      </Tab>
      <Tab eventKey="oct" title="OCT">
        <div>
          {/* Nivel 1 */}
          <Row>
            <Col md={4}>
              <Row>
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <Row>
                        <Col>
                          Helicóptero
                        </Col>
                        <Col>
                          <Form.Control type="text" placeholder="¿Canal?" />
                        </Col>
                      </Row>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Row>
              <Row>
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <Row>
                        <Col>
                          Punto de Encuentro Medios (PEM)
                        </Col>
                        <Col>
                          <Form.Control type="text" placeholder="¿Canal?" />
                        </Col>
                      </Row>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Row>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    <Row>
                      <Col>Puesto de Mando</Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control type="text" placeholder="¿Canal?" />
                        <Form.Control type="text" placeholder="¿Canal?" />
                      </Col>
                      <Col>
                        <Form.Control type="text" placeholder="¿Quién?" />
                        <Form.Control type="text" placeholder="¿Lugar?" />
                      </Col>
                      <Col>
                        <Form.Control type="text" placeholder="¿Canal?" />
                        <Form.Control type="text" placeholder="¿Canal?" />
                      </Col>
                    </Row>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* Nivel 2 */}
          <Row>
            <Col md={3}>
              <Card bg={'primary'}>
                <Card.Body>
                  <Card.Title>
                    <Row>
                      <Col>
                        <Form.Control type="text" placeholder="¿Sector?" />
                        <Form.Control type="text" placeholder="¿Responsable?" />
                        <Form.Control type="text" placeholder="Nº Tareas" />
                      </Col>
                      <Col>
                        <Form.Control type="text" placeholder="¿Canal?" />
                        <Form.Control type="text" placeholder="¿Canal?" />
                        <Form.Control type="text" placeholder="¿Canal de medios?" />
                      </Col>
                    </Row>
                  </Card.Title>
                  {/* Contenido adicional del sector 1 si es necesario */}
                  <Form.Group className="mb-3">
                    <Form.Label style={{ color: 'white' }}>Medios</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card bg={'info'}>
                <Card.Body>
                  <Card.Title>
                    <Row>
                      <Col>
                        <Form.Control type="text" placeholder="¿Sector?" />
                        <Form.Control type="text" placeholder="¿Responsable?" />
                        <Form.Control type="text" placeholder="Nº Tareas" />
                      </Col>
                      <Col>
                        <Form.Control type="text" placeholder="¿Canal?" />
                        <Form.Control type="text" placeholder="¿Canal?" />
                        <Form.Control type="text" placeholder="¿Canal de medios?" />
                      </Col>
                    </Row>
                  </Card.Title>
                  {/* Contenido adicional del sector 1 si es necesario */}
                  <Form.Group className="mb-3">
                    <Form.Label style={{ color: 'black' }}>Medios</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card bg={'warning'}>
                <Card.Body>
                  <Card.Title>
                    <Row>
                      <Col>
                        <Form.Control type="text" placeholder="¿Sector?" />
                        <Form.Control type="text" placeholder="¿Responsable?" />
                        <Form.Control type="text" placeholder="Nº Tareas" />
                      </Col>
                      <Col>
                        <Form.Control type="text" placeholder="¿Canal?" />
                        <Form.Control type="text" placeholder="¿Canal?" />
                        <Form.Control type="text" placeholder="¿Canal de medios?" />
                      </Col>
                    </Row>
                  </Card.Title>
                  {/* Contenido adicional del sector 1 si es necesario */}
                  <Form.Group className="mb-3">
                    <Form.Label style={{ color: 'black' }}>Medios</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card bg={'secondary'}>
                <Card.Body>
                  <Card.Title>
                    <Row>
                      <Col>
                        <Form.Control type="text" placeholder="¿Sector?" />
                        <Form.Control type="text" placeholder="¿Responsable?" />
                        <Form.Control type="text" placeholder="Nº Tareas" />
                      </Col>
                      <Col>
                        <Form.Control type="text" placeholder="¿Canal?" />
                        <Form.Control type="text" placeholder="¿Canal?" />
                        <Form.Control type="text" placeholder="¿Canal de medios?" />
                      </Col>
                    </Row>
                  </Card.Title>
                  {/* Contenido adicional del sector 1 si es necesario */}
                  <Form.Group className="mb-3">
                    <Form.Label style={{ color: 'white' }}>Medios</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Tab>
      <Tab eventKey="date" title={"Date: " + moment(updateDate).format('DD/MM/YYYY HH:mm')} disabled>
      </Tab>
    </Tabs>
  )
}

export default App