import GomObjectType from "../../lib/GomObjectType"

const trafficIndicationArrow = new GomObjectType({
    id: 'arrowIndication',
    type: 'arrow',
    title: 'Indication',
    icon: 'bi bi-arrow-up',
    widthText: true,
    positionText: 'right', // top - right - center - bottom - left
    fieldsText: ["indication"],
    endTextWidthStr: false,
    angle: 0,
    withBorderColor: false,
    withBorderWidth: true,
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "indication": {
                "title": "Indication",
                "description": "Arrow indication",
                "type": "string",
            }
        },
        "required": ['indication']
    },
})

export default trafficIndicationArrow