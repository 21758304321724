import { fabric } from 'fabric'
// http://fabricjs.com/docs/
import canvasConfig from "../../config/canvas.config"

class GomPathShape {

    constructor({ canvas, options }) {
        this.canvas = canvas
        this.options = options

        this.gomObjectType = options.gomObjectType
        this.widthText = options.gomObjectType.widthText
        this.withBorderColor = options.gomObjectType.withBorderColor
        this.withFillColor = options.gomObjectType.withFillColor
        this.angle = options.gomObjectType.angle
        this.pathData = options.gomObjectType.pathData

        this.color = options.color
        this.fill = options.fill
        this.borderCode = options.borderCode
        this.draw()
    }

    draw = () => {
        const pathIcon = new fabric.Path(this.pathData, {
            left: this.canvas.width / 2,
            top: this.canvas.height / 2,
            stroke: this.withBorderColor ? this.color : 'transparent',
            strokeWidth: this.borderCode.strokeWidth,
            strokeDashArray: this.borderCode.strokeDashArray,
            fill: this.withFillColor ? this.fill : 'transparent',
            angle: this.angle,
            originX: 'center',
            originY: 'center',
            cornerColor: canvasConfig.defaultCornerColor,
        })

        pathIcon.scaleToWidth(canvasConfig.iconScaleWidth)

        if (!this.widthText) {
            this.canvas.add(pathIcon)
        } else {
            const group = this.addToGroupWithText([pathIcon], this.fill)
            this.canvas.add(group)
        }

        this.canvas.requestRenderAll()
    }

    addToGroupWithText = (events, textColor) => {
        const text = new fabric.Textbox('', {
            fontSize: canvasConfig.fonstSize,
            fill: textColor,
            originX: 'center',  // left, center, right
            originY: 'center',  // top, center, bottom
            left: events[0].left + 4,
            top: events[0].top,
            editable: true,
            angle: 0,
        })

        const group = new fabric.Group([...events, text], {
            gomObjectType: this.gomObjectType,
            top: text.top,
            left: text.left,
            originX: 'center',
            originY: 'center',
            cornerColor: canvasConfig.defaultCornerColor,
        })

        return group
    }
}

export default GomPathShape