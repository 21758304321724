import GomObjectType from "../../lib/GomObjectType"

const geometWindArrow = new GomObjectType({
    id: 'arrowWind',
    type: 'arrowWind',
    title: 'Wind',
    icon: 'bi bi-wind',
    widthText: true,
    positionText: 'right', // top - right - center - bottom - left
    fieldsText: ["direction", "speed"],
    endTextWidthStr: "Km/h",
    angle: 0,
    withBorderColor: false,
    withBorderWidth: true,
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "direction": {
                "title": "Direction",
                "description": "Wind direction",
                "type": "string",
                "enum": ["N", "NE", "E", "SE", "S", "SW", "W", "NW"],
                "default": "N",
            },
            "speed": {
                "title": "Speed Km/h",
                "description": "Wind speed in Km/h",
                "type": "integer",
                "default": 10,
            }
        },
        "required": ['direction', "speed"]
    },
})

export default geometWindArrow