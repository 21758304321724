import GomObjectType from "../../lib/GomObjectType"

const incidentCatStar = new GomObjectType({
    id: 'incidentStar',
    type: 'star',
    title: 'Category 1',
    icon: 'bi bi-star',
    widthText: true,
    positionText: 'right', // top - right - center - bottom - left
    fieldsText: ["name", "victims"],
    endTextWidthStr: "victims",
    angle: 0,
    withBorderColor: false,
    withBorderWidth: true,
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "name": {
                "title": "Incident",
                "description": "Incident description",
                "type": "string",
            },
            "address": {
                "title": "Address",
                "description": "Incident address",
                "type": "string",
            },
            "victims": {
                "title": "Victims",
                "description": "Incident victims",
                "type": "integer",
            },
        },
        "required": ['name', "address"]
    },
})

export default incidentCatStar