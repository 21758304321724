import React from 'react'
import { Modal, Image } from 'react-bootstrap'

import moment from 'moment'

const CopyModal = ({ show, onClose }) => {

    const handleOnClose = () => {
        onClose()
    }

    return (
        <Modal show={show} size="lg" centered onHide={handleOnClose} >
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="bi bi-patch-question-fill"></i> GOM Fire
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: '#F9CF35' }}>
                <p className='text-center'><Image src='/logo_gom_fire_trans.png' width={200} /></p>
                <p style={{ textAlign: 'center' }}>GOM Fire - Operations Management and Command for Fire Services</p>
                <p style={{ textAlign: 'center', fontStyle: 'italic' }}>Version: 1.0Beta</p>
                <p className='text-center'><Image src='/logo_civilio_trans.png' width={200} /></p>
                <p style={{ textAlign: 'center' }}>Web: <a href="https://civilio.eu" target='_blank'>civilio.eu</a></p>
                <p style={{ textAlign: 'center' }}>E-mail: is@civilio.eu</p>
                <p style={{ textAlign: 'center' }}>Desarrollado con la colaboración de APTB</p>
            </Modal.Body>
            <Modal.Footer>
                <i className="bi bi-r-circle"></i>{moment().year()} GOM Fire - CIVILIO Information Systems s.l.
            </Modal.Footer>
        </Modal>
    )
}

export default CopyModal