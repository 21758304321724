class GomDBManager {
    constructor(dbName, storeName, version) {
        this.dbName = dbName
        this.storeName = storeName
        this.version = version
    }

    openDatabase() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.version)

            request.onerror = () => {
                reject(new Error('Error al abrir la base de datos'))
            }

            request.onsuccess = () => {
                this.db = request.result
                resolve(this.db)
            }

            request.onupgradeneeded = (event) => {
                const db = event.target.result

                // Crear almacén de objetos si aún no existe
                if (!db.objectStoreNames.contains(this.storeName)) {
                    db.createObjectStore(this.storeName, { keyPath: 'id' })
                }
            }
        })
    }

    getLastRecord() {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([this.storeName], 'readonly')
            const objectStore = transaction.objectStore(this.storeName)
            const cursorRequest = objectStore.openCursor(null, 'prev')

            cursorRequest.onsuccess = (event) => {
                const cursor = event.target.result
                if (cursor) {
                    resolve(cursor.value)
                } else {
                    resolve(null)
                }
            }

            cursorRequest.onerror = () => {
                reject(new Error('Error al abrir el cursor'))
            }
        })
    }

    addRecord(data) {
        return new Promise((resolve, reject) => {
            const newData = { id: Date.now(), value: data }
            const transaction = this.db.transaction([this.storeName], 'readwrite')
            const objectStore = transaction.objectStore(this.storeName)
            const addRequest = objectStore.add(newData)

            addRequest.onsuccess = () => {
                resolve()
            }

            addRequest.onerror = () => {
                reject(new Error('Error al agregar el objeto'))
            }
        })
    }

    clearAllRecords() {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([this.storeName], 'readwrite')
            const objectStore = transaction.objectStore(this.storeName)
            const clearRequest = objectStore.clear()

            clearRequest.onsuccess = () => {
                resolve()
            }

            clearRequest.onerror = () => {
                reject(new Error('Error al eliminar los registros'))
            }
        })
    }

    deleteDatabase() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.deleteDatabase(this.dbName)

            request.onsuccess = () => {
                resolve()
            }

            request.onerror = () => {
                reject(new Error('Error al eliminar la base de datos'))
            }
        })
    }
}

export default GomDBManager