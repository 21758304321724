import GomObjectType from "../../lib/GomObjectType"

const resourceTransitPointRect = new GomObjectType({
    id: 'resourceTransitPoint',
    type: 'path',
    title: 'Transit Point',
    icon: 'bi bi-asterisk',
    widthText: true,
    positionText: 'center', // top - right - center - bottom - left
    fieldsText: ["name"],
    endTextWidthStr: "",
    angle: 0,
    withBorderColor: true,
    withBorderWidth: 0,
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "name": {
                "title": "Resource name",
                "description": "Resource name",
                "type": "string",
            },
        },
        "required": ['name']
    },
    pathData: 'M 4 55 L 100 55 M 202 55 L 280 55 M 280 45 L 280 65 L 294 55 L 280 45 M 100 55 A 50 50 0 1 1 202 55 A 50 50 0 1 1 100 55',
    svgData: `<svg width="300" height="110" xmlns="http://www.w3.org/2000/svg">

    <line x1="4" y1="55" x2="100" y2="55" stroke="black" stroke-width="4" />
    <line x1="202" y1="55" x2="280" y2="55" stroke="black" stroke-width="4" />
    <polygon points="280 45, 280 65, 295 55" fill="black" />
    <circle cx="151" cy="55" r="50" fill="none" stroke="black" stroke-width="4" />
</svg>`,
})

export default resourceTransitPointRect