// Defaults 
import defaultTextbox from "./gomObjectTypes/defaultTextbox"
import defaultLine from "./gomObjectTypes/defaultLine"
import defaultPolyLine from "./gomObjectTypes/defaultPolyLine"
import defaultArrow from "./gomObjectTypes/defaultArrow"
import defaultTriangle from "./gomObjectTypes/defaultTriangle"
import defaultRectangle from "./gomObjectTypes/defaultRectangle"
import defaultCircle from "./gomObjectTypes/defaultCircle"
import defaultEllipse from "./gomObjectTypes/defaultEllipse"
import defaultPolygon from "./gomObjectTypes/defaultPolygon"

// Geospatial and Meteo
import geometCompassArrow from "./gomObjectTypes/geometCompassArrow"
import geometWindArrow from "./gomObjectTypes/geometWindArrow"

// Traffic and Transportation
import trafficIndicationArrow from "./gomObjectTypes/trafficIndicationArrow"
import trafficAccessLine from "./gomObjectTypes/trafficAccessLine"
import trafficEvolutionLine from "./gomObjectTypes/trafficEvolutionLine"
import trafficBypassLine from "./gomObjectTypes/trafficBypassLine"
import trafficDirectionLine from "./gomObjectTypes/trafficDirectionLine"
import trafficNoEntryLine from "./gomObjectTypes/trafficNoEntryLine"

// Incident
import incidentCatStar from "./gomObjectTypes/incidentCatStar"
import incidentCatWindow from "./gomObjectTypes/incidentCatWindow"
import incidentCatStarBurst from "./gomObjectTypes/incidentCatStarBurst"

// Resources
import resourceBrigadeRect from "./gomObjectTypes/resourceBrigadeRect"
import resourceGroupRect from "./gomObjectTypes/resourceGroupRect"
import resourceColumnRect from "./gomObjectTypes/resourceColumnRect"
import resourceOtherRect from "./gomObjectTypes/resourceOtherRect"
import resourceInfrastructureRect from "./gomObjectTypes/resourceInfrastructureRect"
import resourceCommandPostRect from "./gomObjectTypes/resourceCommandPostRect"
import resourceTransitPointRect from "./gomObjectTypes/resourceTransitPointRect"
import resourcesAircraft from "./gomObjectTypes/resourceAircraft"

// Actions
import actionAnalysis from "./gomObjectTypes/actionAnalysis"
import actionOffensive from "./gomObjectTypes/actionOffensive"
import actionWaterWheel from "./gomObjectTypes/actionWaterWheel"

const gomObjectTypes = [
    {
        id: 'default',
        title: 'Default Objects',
        icon: 'bi bi-circle-square',
        objects: [
            defaultTextbox,
            defaultLine,
            defaultPolyLine,
            defaultArrow,
            defaultTriangle,
            defaultRectangle,
            defaultCircle,
            defaultEllipse,
            defaultPolygon,
        ]
    },
    {
        id: 'geospatialMeteo',
        title: 'Geospatial and Meteorology',
        icon: 'bi bi-cursor',
        objects: [
            geometCompassArrow,
            geometWindArrow,
        ]
    },
    {
        id: 'traffic',
        title: 'Traffic and Transportation',
        icon: 'bi bi-stoplights',
        objects: [
            trafficIndicationArrow,
            trafficAccessLine,
            trafficEvolutionLine,
            trafficBypassLine,
            trafficDirectionLine,
            trafficNoEntryLine,
        ]
    },
    {
        id: 'incident',
        title: 'Incident/Damages',
        icon: 'bi bi-star',
        objects: [
            incidentCatStar,
            incidentCatWindow,
            incidentCatStarBurst,
        ]
    },
    {
        id: 'resources',
        title: 'Resources/Infrastructures/Logistics',
        icon: 'bi bi-truck',
        objects: [
            resourceBrigadeRect,
            resourceGroupRect,
            resourceColumnRect,
            resourceOtherRect,
            resourceInfrastructureRect,
            resourceCommandPostRect,
            resourceTransitPointRect,
            resourcesAircraft,
        ]
    },
    {
        id: 'actions',
        title: 'Actions',
        icon: 'bi bi-cursor',
        objects: [
            actionAnalysis,
            actionOffensive,
            actionWaterWheel,
        ]
    },
]

// Ordenar el array por la propiedad 'title'
gomObjectTypes.sort((a, b) => {
    const titleA = a.title.toUpperCase()
    const titleB = b.title.toUpperCase()

    if (titleA < titleB) {
        return -1
    }
    if (titleA > titleB) {
        return 1
    }

    return 0 // Son iguales
})

export default gomObjectTypes