import { fabric } from 'fabric'
// http://fabricjs.com/docs/
import canvasConfig from "../../config/canvas.config"

//SVG developments
//https://www.svgviewer.dev/
class GomSvgShape {

    constructor({ canvas, options }) {
        this.canvas = canvas
        this.options = options

        this.gomObjectType = options.gomObjectType
        this.widthText = options.gomObjectType.widthText
        this.withBorderWidth = options.gomObjectType.withBorderWidth
        this.angle = options.gomObjectType.angle
        this.svgData = options.gomObjectType.svgData

        this.color = options.color
        this.fill = options.fill
        this.borderCode = options.borderCode
        this.draw()
    }

    draw = () => {
        fabric.loadSVGFromString(this.svgData, (objects, options) => {
            objects.forEach((object) => {
                object.fill = this.fill
                object.stroke = this.color
            })
            const svgObject = fabric.util.groupSVGElements(objects, options)
            svgObject.set({
                fill: this.fill,
                stroke: this.color,
                left: this.canvas.width / 2,  // Centrar horizontalmente
                top: this.canvas.height / 2,  // Centrar verticalmente
                originX: 'center',
                originY: 'center',
                cornerColor: canvasConfig.defaultCornerColor,
            })

            svgObject.scaleToWidth(canvasConfig.iconScaleWidth)

            if (!this.widthText) {
                this.canvas.add(svgObject)
            } else {
                const group = this.addToGroupWithText([svgObject], this.fill)
                this.canvas.add(group)
            }

            this.canvas.requestRenderAll()
        })
    }

    addToGroupWithText = (events, textColor) => {
        const text = new fabric.Textbox('', {
            fontSize: canvasConfig.fonstSize,
            fill: textColor,
            originX: 'center',  // left, center, right
            originY: 'center',  // top, center, bottom
            left: events[0].left,
            top: events[0].top,
            editable: true,
            angle: 0,
        })

        const group = new fabric.Group([...events, text], {
            gomObjectType: this.gomObjectType,
            top: text.top,
            left: text.left,
            originX: 'center',
            originY: 'center',
            cornerColor: canvasConfig.defaultCornerColor,
        })

        return group
    }
}

export default GomSvgShape